/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getClipHgArea = /* GraphQL */ `
  query GetClipHgArea($areaId: ID!) {
    getClipHgArea(areaId: $areaId) {
      areaId
      name
      location {
        lat
        lon
      }
      createdAt
      updatedAt
    }
  }
`;
export const listClipHgAreas = /* GraphQL */ `
  query ListClipHgAreas(
    $areaId: ID
    $filter: ModelClipHgAreaFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClipHgAreas(
      areaId: $areaId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClipHgUserRestroom = /* GraphQL */ `
  query GetClipHgUserRestroom($user: String!, $restroomId: ID!) {
    getClipHgUserRestroom(user: $user, restroomId: $restroomId) {
      user
      restroomId
      isNotify
      isHiddenSecurityWarning
      createdAt
      updatedAt
      restrooms {
        items {
          restroomId
          prefAreaId
          cityAreaId
          wardAreaId
          name
          address
          location {
            lat
            lon
          }
          latestLocationTime
          isWinter
          isPremium
          useMin
          useMax
          topPosition
          leftPosition
          isMove
          pref {
            areaId
            name
            location {
              lat
              lon
            }
            createdAt
            updatedAt
          }
          city {
            areaId
            name
            location {
              lat
              lon
            }
            createdAt
            updatedAt
          }
          ward {
            areaId
            name
            location {
              lat
              lon
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          devices {
            items {
              deviceId
              restroomId
              co2SensorUsage
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const listClipHgUserRestrooms = /* GraphQL */ `
  query ListClipHgUserRestrooms(
    $user: String
    $restroomId: ModelIDKeyConditionInput
    $filter: ModelClipHgUserRestroomFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClipHgUserRestrooms(
      user: $user
      restroomId: $restroomId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        user
        restroomId
        isNotify
        isHiddenSecurityWarning
        createdAt
        updatedAt
        restrooms {
          items {
            restroomId
            prefAreaId
            cityAreaId
            wardAreaId
            name
            address
            location {
              lat
              lon
            }
            latestLocationTime
            isWinter
            isPremium
            useMin
            useMax
            topPosition
            leftPosition
            pref {
              areaId
              name
              location {
                lat
                lon
              }
              createdAt
              updatedAt
            }
            city {
              areaId
              name
              location {
                lat
                lon
              }
              createdAt
              updatedAt
            }
            ward {
              areaId
              name
              location {
                lat
                lon
              }
              createdAt
              updatedAt
            }
            isMove
            createdAt
            updatedAt
            devices {
              items {
                deviceId
                restroomId
                co2SensorUsage
                createdAt
                updatedAt
              }
              nextToken
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClipHgRestroom = /* GraphQL */ `
  query GetClipHgRestroom($restroomId: ID!) {
    getClipHgRestroom(restroomId: $restroomId) {
      restroomId
      prefAreaId
      cityAreaId
      wardAreaId
      name
      address
      location {
        lat
        lon
      }
      latestLocationTime
      isWinter
      isPremium
      useMin
      useMax
      topPosition
      leftPosition
      isMove
      pref {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      city {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      ward {
        areaId
        name
        location {
          lat
          lon
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      devices {
        items {
          deviceId
          restroomId
          co2SensorUsage
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listClipHgRestrooms = /* GraphQL */ `
  query ListClipHgRestrooms(
    $restroomId: ID
    $filter: ModelClipHgRestroomFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClipHgRestrooms(
      restroomId: $restroomId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        restroomId
        prefAreaId
        cityAreaId
        wardAreaId
        name
        address
        location {
          lat
          lon
        }
        latestLocationTime
        isWinter
        isPremium
        useMin
        useMax
        topPosition
        leftPosition
        isMove
        pref {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        city {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        ward {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        devices {
          items {
            deviceId
            restroomId
            co2SensorUsage
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClipHgDevice = /* GraphQL */ `
  query GetClipHgDevice($deviceId: ID!) {
    getClipHgDevice(deviceId: $deviceId) {
      deviceId
      restroomId
      co2SensorUsage
      createdAt
      updatedAt
      restroom {
        restroomId
        prefAreaId
        cityAreaId
        wardAreaId
        name
        address
        location {
          lat
          lon
        }
        latestLocationTime
        isWinter
        isPremium
        useMin
        useMax
        topPosition
        leftPosition
        isMove
        pref {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        city {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        ward {
          areaId
          name
          location {
            lat
            lon
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        devices {
          items {
            deviceId
            restroomId
            co2SensorUsage
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      toilets {
        items {
          deviceId
          name
          usage
          number
          type
          fixedFlowRate
          doorLockId
          isOccupied
          emergencyButtonId
          isConnected
          disconnectedTime
          hasDisconnectWarning
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listClipHgDevices = /* GraphQL */ `
  query ListClipHgDevices(
    $deviceId: ID
    $filter: ModelClipHgDeviceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClipHgDevices(
      deviceId: $deviceId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceId
        restroomId
        co2SensorUsage
        createdAt
        updatedAt
        restroom {
          restroomId
          prefAreaId
          cityAreaId
          wardAreaId
          name
          address
          location {
            lat
            lon
          }
          latestLocationTime
          isWinter
          isPremium
          useMin
          useMax
          topPosition
          leftPosition
          isMove
          pref {
            areaId
            name
            createdAt
            updatedAt
          }
          city {
            areaId
            name
            createdAt
            updatedAt
          }
          ward {
            areaId
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          devices {
            nextToken
          }
        }
        toilets {
          items {
            deviceId
            name
            usage
            number
            type
            fixedFlowRate
            doorLockId
            isOccupied
            emergencyButtonId
            isConnected
            disconnectedTime
            hasDisconnectWarning
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listDevicesbyRestroomId = /* GraphQL */ `
  query ListDevicesbyRestroomId(
    $restroomId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevicesbyRestroomId(
      restroomId: $restroomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        deviceId
        restroomId
        co2SensorUsage
        createdAt
        updatedAt
        restroom {
          restroomId
          prefAreaId
          cityAreaId
          wardAreaId
          name
          address
          location {
            lat
            lon
          }
          latestLocationTime
          isWinter
          isPremium
          useMin
          useMax
          topPosition
          leftPosition
          isMove
          pref {
            areaId
            name
            createdAt
            updatedAt
          }
          city {
            areaId
            name
            createdAt
            updatedAt
          }
          ward {
            areaId
            name
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          devices {
            nextToken
          }
        }
        toilets {
          items {
            deviceId
            name
            usage
            number
            type
            fixedFlowRate
            doorLockId
            isOccupied
            emergencyButtonId
            isConnected
            disconnectedTime
            hasDisconnectWarning
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getClipHgToilet = /* GraphQL */ `
  query GetClipHgToilet($deviceId: ID!, $number: Int!) {
    getClipHgToilet(deviceId: $deviceId, number: $number) {
      deviceId
      name
      usage
      number
      type
      fixedFlowRate
      doorLockId
      isOccupied
      emergencyButtonId
      isConnected
      disconnectedTime
      hasDisconnectWarning
      createdAt
      updatedAt
    }
  }
`;
export const listClipHgToilets = /* GraphQL */ `
  query ListClipHgToilets(
    $deviceId: ID
    $number: ModelIntKeyConditionInput
    $filter: ModelClipHgToiletFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClipHgToilets(
      deviceId: $deviceId
      number: $number
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        deviceId
        name
        usage
        number
        type
        fixedFlowRate
        doorLockId
        isOccupied
        emergencyButtonId
        isConnected
        disconnectedTime
        hasDisconnectWarning
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClipHgCalcData = /* GraphQL */ `
  query GetClipHgCalcData($calcDataId: ID!) {
    getClipHgCalcData(calcDataId: $calcDataId) {
      calcDataId
      restroomId
      deviceId
      sendDateTime
      carbonDioxide
      temperature
      humidity
      usage
      number
      type
      flushCount
      flowTime
      flowRate
      ev
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listClipHgCalcDatas = /* GraphQL */ `
  query ListClipHgCalcDatas(
    $calcDataId: ID
    $filter: ModelClipHgCalcDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClipHgCalcDatas(
      calcDataId: $calcDataId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        calcDataId
        restroomId
        deviceId
        sendDateTime
        carbonDioxide
        temperature
        humidity
        usage
        number
        type
        flushCount
        flowTime
        flowRate
        ev
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCalcDatasByDeviceId = /* GraphQL */ `
  query ListCalcDatasByDeviceId(
    $deviceId: String
    $sendDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgCalcDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalcDatasByDeviceId(
      deviceId: $deviceId
      sendDateTime: $sendDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        calcDataId
        restroomId
        deviceId
        sendDateTime
        carbonDioxide
        temperature
        humidity
        usage
        number
        type
        flushCount
        flowTime
        flowRate
        ev
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCalcDatasSortByCreatedAt = /* GraphQL */ `
  query ListCalcDatasSortByCreatedAt(
    $deviceId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgCalcDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalcDatasSortByCreatedAt(
      deviceId: $deviceId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        calcDataId
        restroomId
        deviceId
        sendDateTime
        carbonDioxide
        temperature
        humidity
        usage
        number
        type
        flushCount
        flowTime
        flowRate
        ev
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCalcDatasSortByType = /* GraphQL */ `
  query ListCalcDatasSortByType(
    $restroomId: String
    $typeSendDateTime: ModelClipHgCalcDataSortByTypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgCalcDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalcDatasSortByType(
      restroomId: $restroomId
      typeSendDateTime: $typeSendDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        calcDataId
        restroomId
        deviceId
        sendDateTime
        carbonDioxide
        temperature
        humidity
        usage
        number
        type
        flushCount
        flowTime
        flowRate
        ev
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCalcDatasByRestroomId = /* GraphQL */ `
  query ListCalcDatasByRestroomId(
    $restroomId: String
    $sendDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgCalcDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCalcDatasByRestroomId(
      restroomId: $restroomId
      sendDateTime: $sendDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        calcDataId
        restroomId
        deviceId
        sendDateTime
        carbonDioxide
        temperature
        humidity
        usage
        number
        type
        flushCount
        flowTime
        flowRate
        ev
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClipHgWarning = /* GraphQL */ `
  query GetClipHgWarning($warningId: ID!) {
    getClipHgWarning(warningId: $warningId) {
      warningId
      restroomId
      deviceId
      sendDateTime
      warningNumber
      warningType
      usage
      number
      type
      toiletName
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listClipHgWarnings = /* GraphQL */ `
  query ListClipHgWarnings(
    $warningId: ID
    $filter: ModelClipHgWarningFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClipHgWarnings(
      warningId: $warningId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        warningId
        restroomId
        deviceId
        sendDateTime
        warningNumber
        warningType
        usage
        number
        type
        toiletName
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listWarningsSortByTime = /* GraphQL */ `
  query ListWarningsSortByTime(
    $deviceId: String
    $sendDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgWarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarningsSortByTime(
      deviceId: $deviceId
      sendDateTime: $sendDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        warningId
        restroomId
        deviceId
        sendDateTime
        warningNumber
        warningType
        usage
        number
        type
        toiletName
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listWarningsByRestroomId = /* GraphQL */ `
  query ListWarningsByRestroomId(
    $restroomId: String
    $sendDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgWarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWarningsByRestroomId(
      restroomId: $restroomId
      sendDateTime: $sendDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        warningId
        restroomId
        deviceId
        sendDateTime
        warningNumber
        warningType
        usage
        number
        type
        toiletName
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClipHgUseCount = /* GraphQL */ `
  query GetClipHgUseCount($useCountId: ID!) {
    getClipHgUseCount(useCountId: $useCountId) {
      useCountId
      restroomId
      deviceId
      useDateTime
      usage
      type
      number
      washDeadline
      washDeviceId
      washNumber
      washTime
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listClipHgUseCounts = /* GraphQL */ `
  query ListClipHgUseCounts(
    $useCountId: ID
    $filter: ModelClipHgUseCountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClipHgUseCounts(
      useCountId: $useCountId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        useCountId
        restroomId
        deviceId
        useDateTime
        usage
        type
        number
        washDeadline
        washDeviceId
        washNumber
        washTime
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUseCountsByRestroomId = /* GraphQL */ `
  query ListUseCountsByRestroomId(
    $restroomId: String
    $useDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgUseCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUseCountsByRestroomId(
      restroomId: $restroomId
      useDateTime: $useDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        useCountId
        restroomId
        deviceId
        useDateTime
        usage
        type
        number
        washDeadline
        washDeviceId
        washNumber
        washTime
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUseCountsByDeviceId = /* GraphQL */ `
  query ListUseCountsByDeviceId(
    $deviceId: String
    $useDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgUseCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUseCountsByDeviceId(
      deviceId: $deviceId
      useDateTime: $useDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        useCountId
        restroomId
        deviceId
        useDateTime
        usage
        type
        number
        washDeadline
        washDeviceId
        washNumber
        washTime
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUseCountsSortByDeadline = /* GraphQL */ `
  query ListUseCountsSortByDeadline(
    $restroomId: String
    $washDeadline: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgUseCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUseCountsSortByDeadline(
      restroomId: $restroomId
      washDeadline: $washDeadline
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        useCountId
        restroomId
        deviceId
        useDateTime
        usage
        type
        number
        washDeadline
        washDeviceId
        washNumber
        washTime
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUseCountsByWashDeviceId = /* GraphQL */ `
  query ListUseCountsByWashDeviceId(
    $washDeviceId: String
    $washTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClipHgUseCountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUseCountsByWashDeviceId(
      washDeviceId: $washDeviceId
      washTime: $washTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        useCountId
        restroomId
        deviceId
        useDateTime
        usage
        type
        number
        washDeadline
        washDeviceId
        washNumber
        washTime
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClipHgSubtotal = /* GraphQL */ `
  query GetClipHgSubtotal($restroomId: String!, $period: String!) {
    getClipHgSubtotal(restroomId: $restroomId, period: $period) {
      restroomId
      period
      maleBothUse
      maleToiletUse
      maleWashUse
      femaleBothUse
      femaleToiletUse
      femaleWashUse
      multiBothUse
      multiToiletUse
      multiWashUse
      maleCarbonDioxide
      femaleCarbonDioxide
      multiCarbonDioxide
      latestTemperature
      latestHumidity
      maleFlowRate
      femaleFlowRate
      multiFlowRate
      average
      elapsedDays
      ttl
      createdAt
      updatedAt
    }
  }
`;
export const listClipHgSubtotals = /* GraphQL */ `
  query ListClipHgSubtotals(
    $restroomId: String
    $period: ModelStringKeyConditionInput
    $filter: ModelClipHgSubtotalFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listClipHgSubtotals(
      restroomId: $restroomId
      period: $period
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        restroomId
        period
        maleBothUse
        maleToiletUse
        maleWashUse
        femaleBothUse
        femaleToiletUse
        femaleWashUse
        multiBothUse
        multiToiletUse
        multiWashUse
        maleCarbonDioxide
        femaleCarbonDioxide
        multiCarbonDioxide
        latestTemperature
        latestHumidity
        maleFlowRate
        femaleFlowRate
        multiFlowRate
        average
        elapsedDays
        ttl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
