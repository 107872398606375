/**
 * ワーニング定義
 * 201は利用回数閾値が必要
 */
import { toiletTypeText, AlertIconType, alertIcon, Usage } from 'utils/AppConfig';
import { CreateClipHgWarningInput } from 'API';
import dayjs from 'dayjs';
import iconWarning100 from 'images/icon/detail/repair_icon.svg';
import iconWarning200 from 'images/icon/detail/warning_icon.svg';
import iconWarning300 from 'images/icon/detail/cleaning_icon.svg';
import { RestroomItems } from 'custom_hook/useRestroom';
import Toilet from 'utils/Toilet';

// DB取得ワーニングデータ
export type ConvertClipHgWarningInput = CreateClipHgWarningInput & {
  warningId: string;
  sortKey?: string;
};

// ワーニングの範囲
type WarningRange = 'norange' | 'toilet' | 'device' | 'co2' | 'usage';

// 表示ワーニングデータ
export type ViewWarning = {
  title: string;
  text: string;
  type: AlertIconType;
  datetime?: string | undefined;
  warning?: ConvertClipHgWarningInput;
  warningNumber?: number;
  warningId?: string;
  range: WarningRange;
  usage?: Usage;
};

// 置換文字列
const REPLACE_DEVICE_ID = '{___DEVICE_ID___}';
const REPLACE_USE_COUNT = '{___USE_COUNT___}';
const REPLACE_TOILET_TYPE_NAME = '{___TOILET_TYPE_NAME___}';
const REPLACE_TOILET_NAME = '［便器名（CH）］';
const REPLACE_WARNIN_TYPE = '{___WARNIN_TYPE___}';
const REPLACE_THRESHOLD_COUNT = '{利用人数閾値}';

// 表示用ワーニング
const VIEW_WARNINGS: {
  [id: string]: ViewWarning;
} = {
  W_101: {
    title: `流水異常１ ［便器名（CH）］`,
    text: `［便器名（CH）］で流水が止まらない異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_103: {
    title: `システムエラー`,
    text: `エッジデバイスの通信エラーを検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'device',
  },
  W_104: {
    title: `システムエラー`,
    text: `CO2センサーの通信エラーを検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'device',
  },
  W_105: {
    title: `システムエラー`,
    text: `外部制御基板の通信エラーを検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'device',
  },
  W_108: {
    title: `システムエラー`,
    text: `SDカードエラーを検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'device',
  },
  W_109: {
    title: `システムエラー`,
    text: `システムエラーを検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'device',
  },
  W_110: {
    title: `通信エラー`,
    text: `データを受信できません。データを受信するまでしばらくお待ちいただくか、デバイスを確認してください。`,
    type: 'repair',
    range: 'device',
  },
  W_111: {
    title: `流水異常２ ［便器名（CH）］`,
    text: `［便器名（CH）］で流水が流れない異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_112: {
    title: `電磁弁故障 ［便器名（CH）］`,
    text: `通水電磁弁の配線異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_113: {
    title: `電磁弁故障 ［便器名（CH）］`,
    text: `通水電磁弁の配線異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_114: {
    title: `電磁弁故障 ［便器名（CH）］`,
    text: `排水電磁弁の配線異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_115: {
    title: `電磁弁故障 ［便器名（CH）］`,
    text: `排水電磁弁の配線異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_116: {
    title: `電磁弁故障 ［便器名（CH）］`,
    text: `光電センサの異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_117: {
    title: `電磁弁故障 ［便器名（CH）］`,
    text: `押しボタンスイッチの異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_118: {
    title: `電磁弁故障 ［便器名（CH）］`,
    text: `光電センサと押しボタンスイッチの異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_121: {
    title: `電磁弁故障 ［便器名（CH）］`,
    text: `電磁弁の異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_141: {
    title: `システムエラー`,
    text: `エッジデバイスのバージョンに問題があります。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'device',
  },
  W_151: {
    title: `センサ故障 ［便器名（CH）］`,
    text: `ToFセンサの異常を検出しました。サポートセンターへ連絡をお願いします。`,
    type: 'repair',
    range: 'toilet',
  },
  W_201: {
    title: `防犯検知`,
    text: `深夜(22時～4時)に5回/時以上の利用を検知しました。見回るなどの防犯対策が必要です。`,
    type: 'warning',
    range: 'usage',
  },
  W_202: {
    title: `３密検知`,
    text: `CO2濃度が1000ppmを超えました。人が多く集まっている可能性があります。`,
    type: 'warning',
    range: 'co2',
  },
  W_203: {
    title: `手洗い利用異常検知`,
    text: `手洗器の異常利用を検知しました。トイレ目的以外の利用が多い状態です。`,
    type: 'warning',
    range: 'usage',
  },
  W_204: {
    title: `混雑検知`,
    text: `1時間当たり{利用人数閾値}回以上の利用を検知しました。混雑している可能性があります。`,
    type: 'warning',
    range: 'usage',
  },
  W_205: {
    title: `非常ボタン検知`,
    text: `非常ボタンが押されました。直ちに救助が必要です。`,
    type: 'warning',
    range: 'usage',
  },
  W_206: {
    title: `Wi-Fi弱電波検知 ［便器名（CH）］`,
    text: `［便器名（CH）］のWi-Fiの電波が弱いです。設置場所を調整してください。`,
    type: 'warning',
    range: 'toilet',
  },
  W_207: {
    title: `Wi-Fi接続エラー ［便器名（CH）］`,
    text: `［便器名（CH）］のWi-Fi接続が切断されました。接続を確認してください。`,
    type: 'warning',
    range: 'toilet',
  },
  W_208: {
    title: `Wi-Fi接続エラー ［便器名（CH）］`,
    text: `［便器名（CH）］のWi-Fi接続が3時間以上切断されています。接続を確認してください。`,
    type: 'warning',
    range: 'toilet',
  },
  W_210: {
    title: `システム起動`,
    text: `システムが起動しました。`,
    type: 'warning',
    range: 'device',
  },
  W_211: {
    title: `Wi-Fi接続開始 ［便器名（CH）］`,
    text: `［便器名（CH）］のWi-Fi接続が開始しました。`,
    type: 'warning',
    range: 'toilet',
  },
  W_212: {
    title: `システムWi-Fi再接続`,
    text: `システムがWi-Fi再接続しました。`,
    type: 'warning',
    range: 'device',
  },
  W_220: {
    title: `センサ異常 ［便器名（CH）］`,
    text: `ToFセンサが異物を検出しました。設置状態を見直してください。`,
    type: 'warning',
    range: 'toilet',
  },
  W_301: {
    title: `汚れ検知 ［便器名（CH）］`,
    text: `［便器名（CH）］の利用が一定時間以上ありません。汚れている可能性があります。`,
    type: 'dirt',
    range: 'toilet',
  },
};

// warning_type定義
const VIEW_WARNING_TYPES = ['', 'エッジデバイスバッファフル', 'ELTRESコマンドエラー'];

/**
 * warning_typeに対応する文字列を取得
 *
 * @param {number} warningType 警告種別
 * @return {string} warning_typeに対応する文字列
 */
const GetWarningTypeString = (warningType: number | undefined | null): string => {
  if (warningType === undefined) {
    return '';
  }
  if (warningType === null) {
    return '';
  }
  if (warningType >= VIEW_WARNING_TYPES.length || warningType < 0) {
    return '';
  }

  if (VIEW_WARNING_TYPES[warningType]) {
    return VIEW_WARNING_TYPES[warningType];
  }

  return '';
};

/**
 * 警告番号からアイコンを取得
 *
 * @param {number} level 警告レベル
 * @return {string}  警告アイコン
 */
export const GetWarningIcon = (warningNumber: number): string => {
  if (warningNumber < 200) {
    return iconWarning100;
  }
  if (warningNumber < 300) {
    return iconWarning200;
  }

  return iconWarning300;
};

export const GetWarningIconByTopList = (warningNumber: number): string => {
  if (warningNumber >= 300) {
    return alertIcon.dirt;
  }
  if (warningNumber >= 200) {
    return alertIcon.warning;
  }

  return alertIcon.repair;
};

/**
 * VIEW_WARNINGSの使用回数閾値を変換する
 *
 * @param {number} useCount 使用回数閾値
 */
export const ConvertWarningTextUseCount = (text: string, useCount: number | string): string => {
  return text.replace(new RegExp(REPLACE_USE_COUNT, 'g'), useCount.toString());
};

/**
 * 表示用ワーニングデータのキー取得
 *
 * @param {number} warningNumber ClipHgWarningの警告番号
 * @return {string} VIEW_WARNINGSのキー
 */
const GetWarningsKey = (warningNumber: number): string => {
  const key = `W_${warningNumber}`;

  return VIEW_WARNINGS[key] ? key : `W_999`;
};

/**
 * ワーニング番号からAlertIconType取得
 *
 * @param {number} warningNumber  ワーニング番号
 * @return {AlertIconType}
 */
export const GetAlertIconTypeByWarningNumber = (warningNumber: number): AlertIconType => {
  const key = GetWarningsKey(warningNumber);
  const warning = VIEW_WARNINGS[key];

  if (warning) {
    return warning.type;
  }

  return 'good' as AlertIconType;
};

/**
 * テキストの固定文字列を変換
 *
 * @param {string} text 元のテキスト
 * @param {ConvertClipHgWarningInput} warningData ワーニングデータ
 * @param {number} threshold しきい値
 * @return {*}  変換後の文言
 */
const ConvertWarningText = (text: string, warningData: ConvertClipHgWarningInput, threshold?: number): string => {
  const toiletType = warningData.type ? toiletTypeText[warningData.type] : '';
  const warningType = GetWarningTypeString(warningData?.warningType);
  const deviceId = warningData.deviceId || '';
  const toiletName = warningData.toiletName ?? '';
  let ret = text;

  if (threshold !== undefined && threshold !== null) {
    ret = ret.replace(new RegExp(REPLACE_THRESHOLD_COUNT, 'g'), threshold.toString());
  }

  return ret
    .replace(new RegExp(REPLACE_DEVICE_ID, 'g'), deviceId)
    .replace(new RegExp(REPLACE_TOILET_TYPE_NAME, 'g'), toiletType)
    .replace(new RegExp(REPLACE_TOILET_NAME, 'g'), toiletName)
    .replace(new RegExp(REPLACE_WARNIN_TYPE, 'g'), warningType);
};

/**
 * ワーニング範囲取得
 *
 * @param {number} warningNumber  Warning番号
 * @return {WarningRange} ワーニング範囲
 */
export const GetWarningRange = (warningNumber: number): WarningRange => {
  const key = GetWarningsKey(warningNumber);
  const warning = VIEW_WARNINGS[key];

  return warning ? warning.range : 'norange';
};

/**
 * ワーニングデータ取得
 *
 * @param {ConvertClipHgWarningInput} warningData  ClipHgWarningデータ
 * @param {number} threshold しきい値
 * @return {ViewWarning | undefined} 表示用ワーニングデータ
 */
export const GetWarningValue = (
  warningData: ConvertClipHgWarningInput,
  threshold?: number,
): ViewWarning | undefined => {
  const key = GetWarningsKey(warningData.warningNumber);
  const warning = VIEW_WARNINGS[key];
  if (!warning) {
    return undefined;
  }
  const datetime = warningData.sendDateTime ? dayjs(warningData.sendDateTime).format('YYYY/MM/DD HH:mm:ss') : '';

  const text = ConvertWarningText(warning.text, warningData, threshold);
  const title = ConvertWarningText(warning.title, warningData, threshold);

  return {
    ...{
      warningNumber: warningData.warningNumber,
      warningId: warningData.warningId,
      title,
      text,
      type: warning.type,
      datetime,
      range: warning.range,
      usage: warningData.usage as Usage,
    },
  };
};

/**
 * ワーニングが表示データかどうか
 *
 * @param {RestroomItems} restroom チェックするワーニングデータ
 * @param {ConvertClipHgWarningInput} data チェックするワーニングデータ
 * @param {Toilet} toiletAll restroomに紐づく便器データ
 * @param {Usage} usage 用途でチェックする場合に使用する
 *
 * @return {boolean}
 */
// 表示データかどうか
export const CheckViewData = (
  restroom: RestroomItems | undefined,
  data: ConvertClipHgWarningInput,
  toiletAll?: Toilet,
  usage?: Usage,
): boolean => {
  if (!restroom) {
    return true;
  }

  // 定義存在チェック
  const key = GetWarningsKey(data.warningNumber);
  if (!VIEW_WARNINGS[key]) {
    return false;
  }

  // 使用用途チェック
  // チェックしない
  if (usage === undefined || usage === null) {
    return true;
  }
  // 用途が登録されている
  if (data.usage !== undefined && data.usage !== null) {
    if (usage === data.usage) {
      return true;
    }
    // デバイスIDが登録されている
  } else if (data.deviceId !== undefined && data.deviceId !== null && toiletAll !== undefined && toiletAll !== null) {
    const co2toilets = toiletAll.GetUsagesByDeviceId(data.deviceId);
    for (let j = 0; j < co2toilets.length; j += 1) {
      if (usage === co2toilets[j]) {
        return true;
      }
    }
  } else {
    return true;
  }

  return false;
};
