/** @jsx jsx */
import React, { FC, useState, useEffect, useRef } from 'react';
import { jsx, css } from '@emotion/core';
import { Chart } from 'react-google-charts';
import { useWindowSize } from 'custom_hook/useWindowSize';
import { Usage, usageIcon, GraphColor } from 'utils/AppConfig';
import { CreateClipHgSubtotalInput } from 'API';
import {
  IsHoliday,
  ZeroPadding,
  GetSubTotalToUseCount,
  GetSubTotalToWashRate,
  CheckKl,
  getSubTotalDatasByAverageConvertArray,
} from 'utils/Common';
import { Loader } from 'semantic-ui-react';
import dayjs from 'dayjs';
import iconWashUseRateMan from 'images/icon/graph/wash_use_rate/man.svg';
import iconWashUseRateWomen from 'images/icon/graph/wash_use_rate/women.svg';
import iconWashUseRateMulti from 'images/icon/graph/wash_use_rate/multi.svg';
import iconWashUseRateTotal from 'images/icon/graph/wash_use_rate/total.svg';

/**
 * 数値のnull | undefinedチェック
 *
 * @param {(number | null | undefined)} item
 * @return {boolean}
 */
const IsNum = (item: number | null | undefined) => {
  if (item === null || item === undefined) {
    return false;
  }

  return true;
};

const cssMain = css`
  position: relative;
  background-color: #05192c;

  .main-list {
    height: 100vh;
    padding: 0;
    margin: 0;
    list-style: none;

    .WashUseRateScaleOuter {
      display: flex;
      flex-direction: column;
      width: 40%;
      height: 40%;
    }

    .sample-graph {
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
`;

const cssUserCount = css`
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(172deg, rgba(18, 49, 81, 0.7), rgba(19, 70, 157, 0.7));
  border: solid 1px #fff;
  border-radius: 5px;

  .left {
    position: absolute;
    width: 20px;
    height: 100%;
    writing-mode: vertical-rl;
  }

  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 18px;
    padding-top: 2px;
    font-size: 10px;
    color: #fff;
    text-align: center;
  }

  .graph {
    width: 100%;
    height: 100%;
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 8px;
    color: #fff;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }
`;

const cssWeekendCompare = css`
  width: 100%;
  height: 100%;
`;

const cssDetailUserCount = css`
  width: 100%;
  height: 100%;
`;

const cssWashUseRate = css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .wash_use_rate_graph {
    flex-grow: 1;
  }

  .wash_column_icons {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 50px;

    img {
      position: absolute;
      height: 100%;
    }
  }
`;

const cssDetailCo2AndCount = css`
  width: 100%;
  height: 100%;
`;

const cssDetailCo2 = css`
  width: 100%;
  height: 100%;
`;

const cssToiletGraph = css`
  display: inline-block;
  width: 100%;
  height: 100%;

  .toiletgraph-inner {
    display: table;
    width: 100%;
    height: 100%;

    .row {
      display: table-row;
    }

    .icon {
      display: table-cell;
      height: 1rem;
      padding: 5%;
      text-align: center;
      vertical-align: middle;

      .image-box {
        width: 100%;
        height: 100%;
      }

      .man {
        background: url(${usageIcon[1]}) center center no-repeat;
        background-size: contain;
      }

      .women {
        background: url(${usageIcon[2]}) center center no-repeat;
        background-size: contain;
      }

      .multi {
        background: url(${usageIcon[3]}) center center no-repeat;
        background-size: contain;
      }
    }

    .graph {
      display: table-cell;
      padding: 0 1px;
      text-align: center;

      .bar-outer {
        position: relative;
        height: 100%;
        overflow: hidden;
        border-radius: 5px 5px 0 0;

        .inner {
          position: absolute;
          bottom: 0;
          display: block;
          width: 100%;
          padding: 0;
        }

        .inner.total {
          background-color: ${GraphColor.total};
        }

        .inner.man {
          background-color: ${GraphColor.male};
        }

        .inner.women {
          background-color: ${GraphColor.female};
        }

        .inner.multi {
          background-color: ${GraphColor.multi};
        }
      }

      .bar-outer.total {
        border-color: ${GraphColor.total};
      }

      .bar-outer.man {
        border-color: ${GraphColor.male};
      }

      .bar-outer.women {
        border-color: ${GraphColor.female};
      }

      .bar-outer.multi {
        border-color: ${GraphColor.multi};
      }

      .no-data {
        visibility: hidden;
      }
    }
  }
`;

const cssWashrate = css`
  display: table;
  width: 100%;
  height: 100%;

  .row {
    display: table-row;
  }

  .cell {
    position: relative;
    display: table-cell;
  }

  .time {
    width: 28px;
  }

  .yAxis {
    position: relative;
    height: 100%;
    font-size: 10px;
    color: #fff;
    text-align: right;
    list-style: none;
    border-right: solid 1px #9dcce0;

    .pos {
      position: absolute;
      right: -1px;
      width: 100%;
    }

    .pos-transform-bottom {
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
    }

    .pos-transform {
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .graphs {
    height: 100%;
    padding: 0;
  }

  .dotline + .dotline {
    padding-left: 2px;
    margin-left: 2px;
    border-left: 1px dotted #fff;
  }

  .solidline + .solidline {
    padding-left: 2px;
    margin-left: 2px;
    border-left: 1px solid #fff;
  }

  .time-cell {
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
    border-bottom: solid 1px #384d98;

    span {
      font-size: 10px;
      color: #fff;
    }

    &:nth-of-type(2) {
      border-left: solid 1px #384d98;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-right: solid 1px #384d98;
      border-bottom-right-radius: 5px;
    }
  }

  .line {
    position: absolute;
    width: 100%;
  }
`;

const cssWashTransition = css`
  display: table;
  width: 100%;
  height: 100%;

  .row {
    display: table-row;
  }

  .cell {
    position: relative;
    display: table-cell;
  }

  .time {
    width: 25px;
  }

  .yAxis {
    position: relative;
    font-size: 10px;
    color: #fff;
    text-align: right;
    list-style: none;
    border-right: solid 1px #9dcce0;

    .pos {
      position: absolute;
      right: 0;
      line-height: 0.5;
    }
  }

  .graphs {
    height: 100%;
    padding: 0;

    .line {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 45%;
      height: 4px;
      background-color: #761cf8;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  .scale:nth-of-type(2) {
    border-left: 1px solid #fff;
  }

  .scale:last-child {
    border-right: 1px solid #fff;
  }

  .scale + .scale {
    border-left: 1px solid #fff;
  }

  .dotline:last-child {
    border-right: 1px solid #761cf8;
  }

  .dotline + .dotline {
    border-left: 1px solid #761cf8;
  }

  .time-cell {
    position: relative;
    text-align: left;

    span {
      position: absolute;
      top: 0;
      font-size: 10px;
      color: #fff;
    }
  }
`;

/**
 * Top画面の利用回数グラフ
 *
 * @param {CreateClipHgSubtotalInput[]} subTotals SubTotalデータ
 */
type UseCountProps = {
  subTotals: CreateClipHgSubtotalInput[];
};
export const UseCount: FC<UseCountProps> = ({ subTotals }) => {
  const unmounted = useRef(false);
  const [data, setData] = useState<(string | number)[][]>();

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  useEffect(() => {
    const ret: (string | number)[][] = [];
    subTotals.forEach((item) => {
      ret.push([
        parseInt(item.period.substr(-2), 10).toString(),
        GetSubTotalToUseCount([item], 1),
        GetSubTotalToUseCount([item], 2),
        GetSubTotalToUseCount([item], 3),
      ]);
    });

    // メモリリーク回避
    setTimeout(() => {
      if (!unmounted.current) {
        setData(ret);
      }
    }, 1000);
  }, [subTotals]);

  return (
    <section css={cssUserCount}>
      <div className="left">
        <span className="title">利用回数</span>
      </div>
      <div className="graph">
        {data ? (
          <Chart
            width="100%"
            height="100%"
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={[
              [
                { label: 'time', type: 'string' },
                { label: 'male', type: 'number' },
                { label: 'female', type: 'number' },
                { label: 'multi', type: 'number' },
              ],
              ...data,
            ]}
            options={{
              tooltip: { trigger: 'none' },
              backgroundColor: 'transparent',
              colors: ['#57bfed', '#ff4db6', '#62e829'],
              chartArea: { top: 10, left: 28, right: 5, bottom: 30 },
              legend: { position: 'none' },
              isStacked: true,
              vAxis: {
                minValue: 0,
                baselineColor: '#FFF',
                textStyle: {
                  color: '#FFF',
                  fontSize: 5,
                },
                gridlines: {
                  count: 0,
                },
                viewWindowMode: 'maximized',
              },
              hAxis: {
                baselineColor: '#FFF',
                textStyle: {
                  color: '#FFF',
                  fontSize: 5,
                },
              },
            }}
          />
        ) : (
          <Loader active size="medium" />
        )}
      </div>
      <div className="bottom">
        <span>24時間推移</span>
      </div>
    </section>
  );
};

/**
 * 詳細画面の利用回数グラフ
 */
export const DetailUserCount: FC = () => {
  const size = useWindowSize();

  return (
    <section key={`DetailUserCount_${size.height}_${size.width}`} css={cssDetailUserCount}>
      <Chart
        width="100%"
        height="100%"
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            { label: 'time', type: 'number' },
            { label: '合計', type: 'number' },
            { label: '男性用トイレ', type: 'number' },
            { label: '女性用トイレ', type: 'number' },
            { label: '多目的トイレ', type: 'number' },
          ],
          [0, 49, 20, 19, 10],
          [1, 40, 15, 15, 10],
          [2, 80, 20, 50, 10],
          [3, 55, 20, 50, 5],
          [4, 83, 37, 36, 10],
          [5, 64, 26, 28, 10],
          [6, 44, 20, 19, 5],
          [7, 40, 15, 15, 10],
          [8, 70, 20, 50, 10],
          [9, 80, 20, 50, 10],
          [10, 78, 37, 36, 5],
          [11, 64, 26, 28, 10],
          [12, 49, 20, 19, 10],
          [13, 40, 15, 15, 10],
          [14, 80, 20, 50, 5],
          [15, 55, 40, 50, 10],
          [16, 83, 37, 36, 5],
          [17, 64, 26, 28, 10],
          [18, 44, 20, 19, 5],
          [19, 40, 15, 15, 10],
          [20, 70, 20, 50, 10],
          [21, 80, 20, 50, 10],
          [22, 78, 37, 36, 5],
          [23, 64, 26, 28, 10],
        ]}
        options={{
          tooltip: { trigger: 'none' },
          backgroundColor: 'transparent',
          colors: ['#FF8C00', '#57bfed', '#ff4db6', '#62e829'],
          chartArea: { height: '70%', width: '90%' },
          legend: { position: 'none' },
          isStacked: false,
          vAxis: {
            minValue: 0,
            gridlines: {
              minSpacing: 10, // 20ずつ表示totalの最大値を4か5で割る
              count: 5,
            },
            baselineColor: '#FFF',
            textStyle: {
              color: '#FFF',
              fontSize: 8,
            },
            minorGridlines: {
              count: 0,
            },
            viewWindowMode: 'maximized',
          },
          hAxis: {
            baselineColor: '#FFF',
            textStyle: {
              color: '#FFF',
              fontSize: 8,
            },
            gridlines: {
              count: 24,
            },
            minorGridlines: {
              count: 0,
            },
          },
        }}
      />
    </section>
  );
};

/**
 * 詳細画面のCo2グラフ
 */
export const DetailCo2: FC = () => {
  const size = useWindowSize();

  return (
    <section key={`DetailCo2_${size.height}_${size.width}`} css={cssDetailCo2}>
      <Chart
        width="100%"
        height="100%"
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            { label: 'time', type: 'number' },
            { label: '男性用トイレ', type: 'number' },
            { label: '女性用トイレ', type: 'number' },
            { label: '多目的トイレ', type: 'number' },
          ],
          [0, 200, 109, 100],
          [1, 105, 105, 120],
          [2, 200, 500, 130],
          [3, 200, 500, 140],
          [4, 307, 306, 150],
          [5, 206, 208, 160],
          [6, 200, 109, 300],
          [7, 105, 105, 280],
          [8, 200, 500, 270],
          [9, 200, 500, 260],
          [10, 307, 306, 120],
          [11, 206, 208, 150],
          [12, 200, 109, 200],
          [13, 105, 105, 100],
          [14, 200, 500, 300],
          [15, 400, 500, 100],
          [16, 307, 306, 100],
          [17, 206, 208, 100],
          [18, 200, 109, 200],
          [19, 105, 105, 100],
          [20, 200, 500, 100],
          [21, 200, 500, 100],
          [22, 307, 306, 300],
          [23, 206, 208, 100],
        ]}
        options={{
          tooltip: { trigger: 'none' },
          backgroundColor: 'transparent',
          colors: ['#57bfed', '#ff4db6', '#62e829'],
          chartArea: { height: '70%', width: '90%' },
          legend: { position: 'none' },
          isStacked: false,
          curveType: 'function', // スムーズラインを外す場合はこれを消す
          vAxis: {
            maxValue: 500, // 最大値
            minValue: 100, // 最小値
            gridlines: {
              minSpacing: 100, // 20ずつ表示totalの最大値を4か5で割る
              count: 5,
              color: '#FFF',
            },
            minorGridlines: {
              count: 0,
            },
            baselineColor: '#FFF',
            textStyle: {
              color: '#FFF',
              fontSize: 8,
            },
            viewWindowMode: 'maximized',
          },
          hAxis: {
            baselineColor: '#FFF',
            textStyle: {
              color: '#FFF',
              fontSize: 8,
            },
            gridlines: {
              count: 24,
            },
            minorGridlines: {
              count: 0,
            },
          },
        }}
      />
    </section>
  );
};

/**
 * 詳細画面のCo2 + 利用回数グラフ
 *
 * @param {CreateClipHgSubtotalInput} subTotalDatas  subtotalデータ
 * @param {Usage} usages  トイレで使用する用途
 */
type GraphRange = {
  min: number | null;
  max: number | null;
};
type DetailCo2AndCountProps = {
  subTotalDatas: CreateClipHgSubtotalInput[];
  usages: Usage[];
};
export const DetailCo2AndCount: FC<DetailCo2AndCountProps> = ({ subTotalDatas, usages }) => {
  type TitleColumnProps = {
    label: string;
    type: string;
  };
  const unmounted = useRef(false);
  const [columns, setColumns] = useState<TitleColumnProps[][]>([]);
  const [datas, setDatas] = useState<number[][]>([]);
  const [co2Range, setCo2Range] = useState<GraphRange>({
    min: null,
    max: null,
  });
  const size = useWindowSize();

  // 利用人数取得
  const GetUseCount = (data: CreateClipHgSubtotalInput, usage: Usage): number => {
    switch (usage) {
      case 1:
        return data.maleBothUse + data.maleToiletUse + data.maleWashUse;
      case 2:
        return data.femaleBothUse + data.femaleToiletUse + data.femaleWashUse;
      case 3:
        return data.multiBothUse + data.multiToiletUse + data.multiWashUse;
      default:
        return 0;
    }
  };

  const GetCo2 = (data: CreateClipHgSubtotalInput, usage: Usage): number | null => {
    switch (usage) {
      case 1:
        return data.maleCarbonDioxide || null;
      case 2:
        return data.femaleCarbonDioxide || null;
      case 3:
        return data.multiCarbonDioxide || null;
      default:
        return 0;
    }
  };

  const GetColors = (): string[] => {
    const ret: string[] = [];

    const GetColor = (usage: Usage) => {
      switch (usage) {
        case 1:
          return GraphColor.male;
        case 2:
          return GraphColor.female;
        case 3:
          return GraphColor.multi;
        default:
          return GraphColor.male;
      }
    };
    // 利用回数
    usages.forEach((val) => {
      ret.push(GetColor(val));
    });

    // Co2
    usages.forEach((val) => {
      ret.push(GetColor(val));
    });

    return ret;
  };

  type LineSeriesPops = {
    [key in string]: {
      type: string;
      targetAxisIndex: number;
    };
  };
  const GetLineSeries = (): LineSeriesPops => {
    // 0番目のタイトルは数えない。用途に合計を足す
    const start = usages.length;
    const ret: LineSeriesPops = {};
    for (let i = 0; i < usages.length; i += 1) {
      const key = (i + start).toString();
      ret[key] = { type: 'line', targetAxisIndex: 1 };
    }

    return ret;
  };

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // トイレの用途を取得
  useEffect(() => {
    const columnOther: TitleColumnProps[] = [{ label: 'time', type: 'string' }];
    const columnCount: TitleColumnProps[] = [];
    const columnCo2: TitleColumnProps[] = [];
    usages.forEach((val) => {
      switch (val) {
        case 1:
          columnCount.push({ label: '男性用トイレ利用人数', type: 'number' });
          columnCo2.push({ label: '男性用トイレCo2濃度', type: 'number' });
          break;
        case 2:
          columnCount.push({ label: '女性用トイレ利用人数', type: 'number' });
          columnCo2.push({ label: '女性用トイレCo2濃度', type: 'number' });
          break;
        case 3:
          columnCount.push({ label: '多目的トイレ利用人数', type: 'number' });
          columnCo2.push({ label: '多目的トイレCo2濃度', type: 'number' });
          break;
        default:
          break;
      }
    });

    if (!unmounted.current) {
      setColumns([[...columnOther, ...columnCount, ...columnCo2]]);
    }
  }, [usages]);

  useEffect(() => {
    const newCo2Range: GraphRange = {
      min: null,
      max: null,
    };
    const updateDatas: number[][] = [];
    subTotalDatas.forEach((item) => {
      const ret: number[] = [];
      // 時間
      ret.push(Number(item.period.substr(item.period.length - 2)));
      // 利用回数
      usages.forEach((usage) => {
        ret.push(GetUseCount(item, usage));
      });
      // CO2
      usages.forEach((usage) => {
        const nowValue = GetCo2(item, usage);
        if (nowValue !== null) {
          if (newCo2Range.max === null || newCo2Range.max < nowValue) {
            newCo2Range.max = nowValue;
          }
          if (newCo2Range.min === null || newCo2Range.min > nowValue) {
            newCo2Range.min = nowValue;
          }
        }
        ret.push(nowValue as number);
      });

      updateDatas.push(ret);
    });

    if (!unmounted.current) {
      setCo2Range({
        min: Math.floor((newCo2Range.min ?? 0) / 100) * 100,
        max: Math.ceil((newCo2Range.max ?? 0) / 100) * 100,
      });
    }
    if (!unmounted.current) {
      setDatas(updateDatas);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotalDatas]);

  if (columns.length <= 0 || datas.length <= 0) {
    return <div />;
  }

  return (
    <section key={`DetailCo2AndCount_${size.height}_${size.width}`} css={cssDetailCo2AndCount}>
      <Chart
        width="100%"
        height="100%"
        chartType="ComboChart"
        loader={<div>Loading Chart</div>}
        data={[...columns, ...datas]}
        options={{
          isStacked: true,
          tooltip: { trigger: 'none' },
          seriesType: 'bars',
          series: GetLineSeries(),
          backgroundColor: 'transparent',
          colors: GetColors(),
          chartArea: { height: '70%', width: '90%' },
          legend: { position: 'none' },
          curveType: 'function', // スムーズラインを外す場合はこれを消す
          vAxis: {
            baselineColor: '#FFF',
            textStyle: {
              color: '#FFF',
              fontSize: 10,
            },
            minorGridlines: {
              count: 0,
            },
            gridlines: {
              count: 5,
            },
          },
          vAxes: {
            0: {
              viewWindow: {
                min: 0,
              },
            },
            1: {
              viewWindow: {
                min: 0,
                max: co2Range.max,
              },
              gridlines: {
                count: 0,
              },
            },
          },
          hAxis: {
            baselineColor: '#FFF',
            textStyle: {
              color: '#FFF',
              fontSize: 10,
            },
            gridlines: {
              count: datas.length,
            },
            minorGridlines: {
              count: 0,
            },
          },
        }}
      />
    </section>
  );
};

/**
 * トイレグラフ
 */
type ToiletGraphProps = {
  Percent: {
    Male?: number | undefined | null;
    Female?: number | undefined | null;
    Multi?: number | undefined | null;
    Total?: number | undefined | null;
  };
  IsIcons?: boolean;
  IsThin?: boolean;
  height?: string;
  sideSpace?: string;
};
export const ToiletGraph: FC<ToiletGraphProps> = ({
  Percent,
  sideSpace,
  IsIcons = false,
  IsThin = false,
  height = '30%',
}) => {
  const border = IsThin ? 'solid 1px' : 'solid 2px';
  const count = Percent.Total !== undefined && Percent.Total !== null ? 4 : 3;

  const cssToiletGraphUpdate = css`
    ${cssToiletGraph}
    ${sideSpace !== undefined &&
    `
    .toiletgraph-inner {
    padding: 0 ${sideSpace};
    }
    `}
    .icon {
      width: calc(100% / ${count});
    }

    .graph {
      width: calc(100% / ${count});

      .bar-outer {
        border: ${border};
      }
    }
  `;

  return (
    <section css={cssToiletGraphUpdate}>
      <div className="toiletgraph-inner">
        {/* アイコン */}
        {IsIcons && (
          <ul className="row" style={{ height: `${height}` }}>
            {Percent.Total !== undefined && (
              <li className="icon">
                <img src={usageIcon[1]} alt="icon" />
              </li>
            )}
            {Percent.Male !== undefined && (
              <li className="icon">
                <div className="image-box man" />
              </li>
            )}
            {Percent.Female !== undefined && (
              <li className="icon">
                <div className="image-box women" />
              </li>
            )}
            {Percent.Multi !== undefined && (
              <li className="icon">
                <div className="image-box multi" />
              </li>
            )}
          </ul>
        )}
        {/* グラフ */}
        <ul className="row" style={{ height: '100%' }}>
          {Percent.Total !== undefined && (
            <li className="graph">
              <div
                className={`bar-outer total ${
                  !IsNum(Percent.Male) && !IsNum(Percent.Female) && !IsNum(Percent.Multi) && 'no-data'
                }`}
              >
                <span className="inner total" style={{ height: `${Percent.Total}%` }} />
              </div>
            </li>
          )}
          <li className="graph">
            <div className={`bar-outer man ${!IsNum(Percent.Male) && 'no-data'}`}>
              <span className="inner man" style={{ height: `${Percent.Male}%` }} />
            </div>
          </li>
          <li className="graph">
            <div className={`bar-outer women ${!IsNum(Percent.Female) && 'no-data'}`}>
              <span className="inner women" style={{ height: `${Percent.Female}%` }} />
            </div>
          </li>
          <li className="graph">
            <div className={`bar-outer multi ${!IsNum(Percent.Multi) && 'no-data'}`}>
              <span className="inner multi" style={{ height: `${Percent.Multi}%` }} />
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

/**
 * 手洗い率グラフ
 *
 * @param {CreateClipHgSubtotalInput} subTotalDatas  subtotalデータ
 * @param {Usage} usages  トイレで使用する用途
 */
type WashRateProps = {
  subTotalDatas: CreateClipHgSubtotalInput[];
  usages: Usage[];
};
export const WashRate: FC<WashRateProps> = ({ subTotalDatas, usages }) => {
  type WashRateDataProps = {
    Hour: number;
    Male: number | undefined | null;
    Female: number | undefined | null;
    Multi: number | undefined | null;
    Total: number | undefined | null;
  };
  const unmounted = useRef(false);
  const size = useWindowSize();
  const [datas, setDatas] = useState<WashRateDataProps[]>([]);
  const yAxis = [50];

  // 表示する値を取得
  const GetValue = (item: CreateClipHgSubtotalInput, usage?: Usage): number | null | undefined => {
    const Get = (both: number, toilet: number): number | null => {
      const total = both + toilet;

      return total === 0 ? null : Math.floor((both / total) * 100);
    };

    if (usages !== undefined && usages !== null && usage !== undefined && usage !== null) {
      if (!usages.some((v) => v === usage)) {
        return undefined;
      }
    }

    if (usage !== undefined && usage !== null) {
      switch (usage) {
        case 1:
          return Get(item.maleBothUse, item.maleToiletUse);
        case 2:
          return Get(item.femaleBothUse, item.femaleToiletUse);
        case 3:
          return Get(item.multiBothUse, item.multiToiletUse);
        default:
          return undefined;
      }
    } else {
      return Get(
        item.maleBothUse + item.femaleBothUse + item.multiBothUse,
        item.maleToiletUse + item.femaleToiletUse + item.multiToiletUse,
      );
    }
  };

  // マウントチェック
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // データ更新
  useEffect(() => {
    const updateDatas: WashRateDataProps[] = [];
    subTotalDatas.forEach((item) => {
      updateDatas.push({
        Hour: Number(item.period.substr(item.period.length - 2)),
        Male: GetValue(item, 1),
        Female: GetValue(item, 2),
        Multi: GetValue(item, 3),
        Total: GetValue(item),
      });
    });

    if (!unmounted.current) {
      setDatas(updateDatas);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotalDatas]);

  if (datas.length <= 0) {
    return <div />;
  }

  return (
    <section css={cssWashrate} key={`WashRate_${size.height}_${size.width}`}>
      <ul className="row">
        {/* Y軸 手洗い率 */}
        <li className="cell time">
          <div className="yAxis">
            <span className="pos pos-transform" style={{ top: 0 }}>
              100 -
            </span>
            {yAxis.map((val) => {
              return (
                <span
                  key={`WashRate_yAxis_${val}`}
                  className="pos pos-transform"
                  style={{ top: `calc(100% - ${val}% - 1px)` }}
                >
                  {val} -
                </span>
              );
            })}
            <span className="pos pos-transform-bottom" style={{ bottom: '2px' }}>
              0 -
            </span>
          </div>
        </li>
        {/* グラフ */}
        {datas.map((item, index) => {
          const key = `WashRate_graphs_${index}`;

          return (
            <li key={key} className="graphs cell dotline">
              <ToiletGraph
                Percent={{ Male: item.Male, Female: item.Female, Multi: item.Multi, Total: item.Total }}
                sideSpace="3px"
              />
              <p className="line" style={{ borderBottom: '1px dotted red', top: 'calc(50%)' }} />
              <p className="line" style={{ borderBottom: '1px dotted #9dcce0', top: 'calc(10%)' }} />
              <p className="line" style={{ borderBottom: '1px solid #9dcce0', bottom: 0 }} />
            </li>
          );
        })}
      </ul>
      <ul className="row">
        {/* Y軸の空白 */}
        <li className="cell time" style={{ height: '1em' }} />
        {/* グラフと時間の隙間 */}
        {datas.map((item, index) => {
          const key = `WashRate_graphs_space_${index}`;

          return <li key={key} className="graphs cell solidline" style={{ borderBottom: 'solid 3px #fff' }} />;
        })}
      </ul>
      <ul className="row" style={{ height: '30px' }}>
        {/* Y軸の空白 */}
        <li className="cell time" />
        {/* X軸 時間 */}
        {datas.map((item, index) => {
          const key = `WashRate_graphs_time_${index}`;

          return (
            <li key={key} className="graphs cell time-cell">
              <span>{item.Hour}</span>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

/**
 * 週末比較グラフ
 */
type WeekendComparePops = {
  subTotalDatas: CreateClipHgSubtotalInput[];
  subTotalDatasAverage: CreateClipHgSubtotalInput[];
};
export const WeekendCompare: FC<WeekendComparePops> = ({ subTotalDatas, subTotalDatasAverage }) => {
  const [datas, setDatas] = useState<(number | null)[][]>([]);
  const unmounted = useRef(false);
  const size = useWindowSize();

  // マウントチェック
  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  // 表示データ生成
  useEffect(() => {
    type DayCountType = {
      [key in string]: number;
    };
    // 休日と平日に切り分け
    const days: DayCountType = {};
    const holidays: DayCountType = {};
    const dayDatas: CreateClipHgSubtotalInput[] = [];
    const holidayDatas: CreateClipHgSubtotalInput[] = [];
    subTotalDatas.forEach((item) => {
      const date = item.period.substr(0, item.period.length - 2);

      if (IsHoliday(date)) {
        holidays[date] = 0;
        holidayDatas.push(item);
      } else {
        days[date] = 0;
        dayDatas.push(item);
      }
    });

    // 合計値取得
    const GetTotal = (value: CreateClipHgSubtotalInput): number => {
      return (
        value.femaleBothUse +
        value.femaleToiletUse +
        value.femaleWashUse +
        value.maleBothUse +
        value.maleToiletUse +
        value.maleWashUse +
        value.multiBothUse +
        value.multiToiletUse +
        value.multiWashUse
      );
    };

    // 時間単位の平均値
    const GetTotalByHour = (subTotal: CreateClipHgSubtotalInput[], hour: number, averageCount: number) => {
      const hourTotal = subTotal
        .filter((item) => item.period.slice(-2) === ZeroPadding(hour, 2))
        .reduce((total, item) => {
          return total + GetTotal(item);
        }, 0);

      return averageCount === 0 ? 0 : Math.floor(hourTotal / averageCount);
    };

    // 年間平均データを配列に変換
    const averages = getSubTotalDatasByAverageConvertArray(subTotalDatasAverage);

    // 時間単位に切り分け
    const result = Array<(number | null)[]>(24)
      .fill([0, 0, 0])
      .map((val, index) => {
        return [
          index,
          GetTotalByHour(dayDatas, index, Object.keys(days).length),
          GetTotalByHour(holidayDatas, index, Object.keys(holidays).length),
          averages[index] ?? null,
        ];
      });

    if (!unmounted.current) {
      setDatas(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotalDatas, subTotalDatasAverage]);

  return (
    <section key={`WeekendCompare_${size.height}_${size.width}`} css={cssWeekendCompare}>
      <Chart
        width="100%"
        height="100%"
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            { label: 'time', type: 'number' },
            { label: '平日', type: 'number' },
            { label: '休日', type: 'number' },
            { label: '年間', type: 'number' },
          ],
          ...datas,
        ]}
        options={{
          tooltip: { trigger: 'none' },
          backgroundColor: 'transparent',
          colors: ['#ffffff', '#ff6764', '#008748'],
          chartArea: { height: '80%', width: '90%' },
          legend: { position: 'none' },
          isStacked: false,
          vAxis: {
            minValue: 0,
            baselineColor: '#FFF',
            textStyle: {
              color: '#FFF',
            },
            minorGridlines: {
              count: 0,
            },
            viewWindowMode: 'maximized',
          },
          hAxis: {
            baselineColor: '#FFF',
            textStyle: {
              color: '#FFF',
            },
            gridlines: {
              count: 24,
            },
            minorGridlines: {
              count: 0,
            },
          },
        }}
      />
    </section>
  );
};

/**
 * 手洗い利用率グラフ
 *
 * @param {CreateClipHgSubtotalInput} subTotalDatas  subtotalデータ
 * @param {Usage} usages  トイレで使用する用途
 */
type WashUseRateProps = {
  subTotalDatas: CreateClipHgSubtotalInput[];
  usages: Usage[];
};
export const WashUseRate: FC<WashUseRateProps> = ({ subTotalDatas, usages }) => {
  // SubTotalの集計データ
  type TotalData = {
    both: number;
    toilet: number;
    wash: number;
    total: number;
    per: {
      both: number;
      toilet: number;
      wash: number;
    };
  };
  const size = useWindowSize();

  // パーセント取得
  const GetPer = (num: number, total: number) => {
    if (total <= 0) {
      return 0;
    }

    return Math.round((num / total) * 100);
  };

  // 0の場合にnullを返す
  const DownToNull = (val: number, retDefault?: string | number) => {
    const down = retDefault === undefined ? 0 : 10;

    if (val <= down) {
      return null;
    }

    return retDefault ?? val;
  };

  // 用途毎の集計データ取得
  const GetBoth = (usage: number) => {
    switch (usage) {
      case 1:
        return subTotalDatas.reduce((p, x) => p + x.maleBothUse, 0);
      case 2:
        return subTotalDatas.reduce((p, x) => p + x.femaleBothUse, 0);
      case 3:
        return subTotalDatas.reduce((p, x) => p + x.multiBothUse, 0);
      default:
        return 0;
    }
  };
  const GetToilet = (usage: number) => {
    switch (usage) {
      case 1:
        return subTotalDatas.reduce((p, x) => p + x.maleToiletUse, 0);
      case 2:
        return subTotalDatas.reduce((p, x) => p + x.femaleToiletUse, 0);
      case 3:
        return subTotalDatas.reduce((p, x) => p + x.multiToiletUse, 0);
      default:
        return 0;
    }
  };
  const GetWash = (usage: number) => {
    switch (usage) {
      case 1:
        return subTotalDatas.reduce((p, x) => p + x.maleWashUse, 0);
      case 2:
        return subTotalDatas.reduce((p, x) => p + x.femaleWashUse, 0);
      case 3:
        return subTotalDatas.reduce((p, x) => p + x.multiWashUse, 0);
      default:
        return 0;
    }
  };

  // 集計データ取得
  const GetTotal = (usage: Usage): TotalData => {
    const both = GetBoth(usage);
    const toilet = GetToilet(usage);
    const wash = GetWash(usage);
    const total = both + toilet + wash;

    const perBoth = GetPer(both, total);
    const perWash = GetPer(wash, total);
    const perToilet = GetPer(toilet, total);

    return {
      both,
      toilet,
      wash,
      total,
      per: {
        both: perBoth,
        wash: perWash,
        toilet: perToilet,
      },
    };
  };

  // 表示データ取得
  const GetDatas = () => {
    const male = GetTotal(1);
    const female = GetTotal(2);
    const multi = GetTotal(3);

    const totalBoth = male.both + female.both + multi.both;
    const totalWash = male.wash + female.wash + multi.wash;
    const totalToilet = male.toilet + female.toilet + multi.toilet;
    const totalTotal = totalBoth + totalWash + totalToilet;
    const perBoth = GetPer(totalBoth, totalTotal);
    const perToilet = GetPer(totalToilet, totalTotal);
    const perWash = GetPer(totalWash, totalTotal);

    const noData = ['', null, '', null, '', null, '', null, null];

    const ret: (string | number | null)[][] = [
      [
        ``,
        perBoth,
        DownToNull(perBoth, `${perBoth}%`),
        perToilet,
        DownToNull(perToilet, `${perToilet}%`),
        perWash,
        DownToNull(perWash, `${perWash}%`),
        DownToNull(perBoth),
        DownToNull(perBoth + perToilet),
      ],
    ];

    if (usages.some((v) => v === 1)) {
      ret.push([
        '',
        male.per.both,
        DownToNull(male.per.both, `${male.per.both}%`),
        male.per.toilet,
        DownToNull(male.per.toilet, `${male.per.toilet}%`),
        male.per.wash,
        DownToNull(male.per.wash, `${male.per.wash}%`),
        DownToNull(male.per.both),
        DownToNull(male.per.both + male.per.toilet),
      ]);
    } else {
      ret.push(noData);
    }
    if (usages.some((v) => v === 2)) {
      ret.push([
        '',
        female.per.both,
        DownToNull(female.per.both, `${female.per.both}%`),
        female.per.toilet,
        DownToNull(female.per.toilet, `${female.per.toilet}%`),
        female.per.wash,
        DownToNull(female.per.wash, `${female.per.wash}%`),
        DownToNull(female.per.both),
        DownToNull(female.per.both + female.per.toilet),
      ]);
    } else {
      ret.push(noData);
    }
    if (usages.some((v) => v === 3)) {
      ret.push([
        '',
        multi.per.both,
        DownToNull(multi.per.both, `${multi.per.both}%`),
        multi.per.toilet,
        DownToNull(multi.per.toilet, `${multi.per.toilet}%`),
        multi.per.wash,
        DownToNull(multi.per.wash, `${multi.per.wash}%`),
        DownToNull(multi.per.both),
        DownToNull(multi.per.both + multi.per.toilet),
      ]);
    } else {
      ret.push(noData);
    }

    return ret;
  };

  return (
    <section key={`WashUseRate_${size.height}_${size.width}`} css={cssWashUseRate}>
      <div className="wash_use_rate_graph">
        <Chart
          width="100%"
          height="100%"
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={[
            [
              { label: 'time', type: 'string' },
              { label: '共に利用', type: 'number' },
              { type: 'string', role: 'annotation' },
              { label: '手洗い無し', type: 'number' },
              { type: 'string', role: 'annotation' },
              { label: '手洗いのみ', type: 'number' },
              { type: 'string', role: 'annotation' },
              { label: '共に利用', type: 'number' },
              { label: '手洗い無し', type: 'number' },
            ],
            ...GetDatas(),
          ]}
          options={{
            tooltip: { trigger: 'none' },
            series: {
              3: { type: 'line', targetAxisIndex: 0, color: '#BFB22A', lineDashStyle: [4, 4] },
              4: { type: 'line', targetAxisIndex: 0, color: '#BFB22A', lineDashStyle: [4, 4] },
            },
            bar: { groupWidth: 60 },
            backgroundColor: 'transparent',
            colors: ['#00a2b8', '#ffc540', '#d9d5d5'],
            chartArea: { height: '95%', width: '80%' },
            legend: { position: 'none' },
            isStacked: true,
            vAxis: {
              minValue: 0,
              maxValue: 100,
              gridlines: {
                count: 5,
                color: '#fff',
              },
              baselineColor: '#fff',
              textStyle: {
                color: '#FFF',
              },
              minorGridlines: {
                count: 0,
              },
              viewWindowMode: 'maximized',
            },
            hAxis: {
              baselineColor: '#fff',
              textStyle: {
                color: '#FFF',
              },
              gridlines: {
                count: 0,
              },
              minorGridlines: {
                count: 0,
              },
            },
            annotations: {
              highContrast: false,
              textStyle: {
                color: 'black',
              },
            },
          }}
        />
      </div>
      <div className="wash_column_icons">
        <img src={iconWashUseRateTotal} alt="icon" style={{ left: '54px' }} />
        {usages.some((v) => v === 1) && <img src={iconWashUseRateMan} alt="icon" style={{ left: '158px' }} />}
        {usages.some((v) => v === 2) && <img src={iconWashUseRateWomen} alt="icon" style={{ left: '259px' }} />}
        {usages.some((v) => v === 3) && <img src={iconWashUseRateMulti} alt="icon" style={{ left: '359px' }} />}
      </div>
    </section>
  );
};

/**
 * 手洗い推移グラフ
 *
 * @param {string} restroomId トイレID
 * @param {string} height グラフの高さ
 * @param {CreateClipHgSubtotalInput} subTotals subtotalデータ
 * @param {Usage[]} usages 使用全用途
 */
type WashTransitionGraph = {
  hour: number;
  male: number | null | undefined;
  female: number | null | undefined;
  multi: number | null | undefined;
};
type WashTransitionProps = {
  restroomId: string;
  height?: string;
  subTotals: CreateClipHgSubtotalInput[];
  usages: Usage[];
};
export const WashTransition: FC<WashTransitionProps> = ({ restroomId, height = '30%', subTotals, usages }) => {
  const SPLIT_COUNT = 3;
  const unmounted = useRef(false);
  const [datas, setDatas] = useState<WashTransitionGraph[]>();
  const key = `WashTransition_${restroomId}`;
  const yAxis = [50];

  useEffect(() => {
    if (!subTotals) {
      return;
    }

    const newDatas: WashTransitionGraph[] = [];
    const len = Math.floor(subTotals.length / SPLIT_COUNT);
    for (let i = 0; i < len; i += 1) {
      const sliceData = subTotals.slice(i * SPLIT_COUNT, i * SPLIT_COUNT + SPLIT_COUNT);
      newDatas.push({
        hour: parseInt(sliceData[0].period.substr(-2), 10),
        male: GetSubTotalToWashRate(sliceData, 1, usages),
        female: GetSubTotalToWashRate(sliceData, 2, usages),
        multi: GetSubTotalToWashRate(sliceData, 3, usages),
      });
    }

    if (!unmounted.current) {
      setDatas(newDatas);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotals]);

  return (
    <section css={cssWashTransition} key={key}>
      {datas ? (
        <React.Fragment>
          <ul className="row">
            {/* Y軸 手洗い率 */}
            <li className="cell time">
              <div className="yAxis" style={{ height: `${height}` }}>
                <span className="pos" style={{ lineHeight: '2em', bottom: 0, right: '0.5em' }}>
                  [%]
                </span>
              </div>
              <div className="yAxis" style={{ height: `calc(100% - ${height})` }}>
                <span className="pos" style={{ top: 0 }}>
                  100 -
                </span>
                {yAxis.map((val) => {
                  return (
                    <span key={`${key}_yAxis_${val}`} className="pos" style={{ top: `calc(100% - ${val}%)` }}>
                      {val} -
                    </span>
                  );
                })}
                <span className="pos" style={{ bottom: 0 }}>
                  0 -
                </span>
              </div>
            </li>
            {/* グラフ */}
            {datas.map((item) => {
              return (
                <li key={`${key}_graphs_${item.hour}`} className="graphs cell dotline">
                  <ToiletGraph
                    Percent={{ Male: item.male, Female: item.female, Multi: item.multi }}
                    IsIcons
                    IsThin
                    height={height}
                  />
                </li>
              );
            })}
          </ul>
          <ul className="row">
            {/* Y軸の空白 */}
            <li className="cell time" style={{ height: '5px' }} />
            {/* 時間軸 */}
            {datas.map((item) => {
              return (
                <li
                  key={`${key}_graphs_space_${item.hour}`}
                  className="graphs cell scale"
                  style={{ borderTop: 'solid 1px #fff' }}
                />
              );
            })}
          </ul>
          <ul className="row">
            {/* Y軸の空白 */}
            <li className="cell time" style={{ height: '15px' }} />
            {/* X軸 時間軸数値 */}
            {datas.map((item, index) => {
              const left = item.hour >= 10 ? 0.5 : 0.25;
              const right = datas[0].hour >= 10 ? 0.5 : 0.25;

              return (
                <li key={`${key}_graphs_time_${item.hour}`} className="graphs cell time-cell">
                  <span style={{ left: `-${left}em` }}>{item.hour}</span>
                  {datas.length - 1 === index && <span style={{ right: `-${right}em` }}>{datas[0].hour}</span>}
                  <div className="line" />
                </li>
              );
            })}
          </ul>
        </React.Fragment>
      ) : (
        <Loader active size="medium" />
      )}
    </section>
  );
};

const cssFlowRateMin = css`
  position: relative;
  width: 100%;
  height: 100%;

  .flow-rate-min-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    ul {
      display: flex;
      flex-direction: row;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .v-axis-width {
      width: 20px;
    }

    .v-axis {
      position: relative;
      border-right: solid 1px #fff;

      .num {
        position: absolute;
        line-height: 0px;
        color: #fff;
      }

      .v-line {
        position: absolute;
        right: 0;
        width: 3px;
        border-top: solid 1px #fff;
      }

      .v-line.max {
        top: 0%;
      }

      .v-line.half {
        top: 50%;
      }

      .max {
        top: 0;
      }

      .half {
        top: 50%;
      }

      .bottom {
        top: 100%;
      }
    }

    .h-axis {
      flex-grow: 1;

      .times {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 10px;
        color: #fff;

        div {
          position: relative;
        }

        .h-line {
          position: absolute;
          top: 0;
          left: 50%;
          height: 3px;
          border-left: solid 1px #fff;
        }
      }
    }
  }
`;
/**
 * TOP画面水流グラフ
 *
 * @param {CreateClipHgSubtotalInput[]} subTotals SubTotalデータ
 */
type FlowRateMinProps = {
  restroomId: string;
  subTotals: CreateClipHgSubtotalInput[];
};
export const FlowRateMin: FC<FlowRateMinProps> = ({ restroomId, subTotals }) => {
  const unmounted = useRef(false);
  const [datas, setDatas] = useState<(number | null | undefined)[][]>([]);
  const [isKL, setIsKL] = useState(true);
  const [max, setMax] = useState(0);

  // KL変換
  const klFloor = (val: number | null, is?: boolean): number => {
    const useKl = is ?? isKL;
    if (val === null) {
      return 0;
    }

    // 小数点２桁まで
    return useKl ? Math.floor((val / 1000) * 100) / 100 : val;
  };

  // 丸め
  const RoundNum = (val: number | null): number => {
    if (val === null) {
      return 0;
    }

    let num = 10 ** (val.toString().length - 1);
    if (num < 1) {
      num = 1;
    }

    return Math.ceil(val / num) * num;
  };

  // データ更新
  useEffect(() => {
    if (!subTotals) {
      return;
    }

    const newDatas: (number | null | undefined)[][] = [];
    let newIsKL = false;
    let newMax = 0;
    subTotals.forEach((item) => {
      const total = (() => {
        if (!IsNum(item.maleFlowRate) && !IsNum(item.femaleFlowRate) && !IsNum(item.multiFlowRate)) {
          return null;
        }

        return (item.maleFlowRate ?? 0) + (item.femaleFlowRate ?? 0) + (item.multiFlowRate ?? 0);
      })();

      const count = Math.floor(total as number);
      newDatas.push([parseInt(item.period.substr(-2), 10), count]);
      if (count >= 1000) {
        newIsKL = true;
      }
      if (newMax < count) {
        newMax = count;
      }
    });

    if (!unmounted.current) {
      setDatas(newDatas);
    }
    if (!unmounted.current) {
      setIsKL(newIsKL);
    }
    if (!unmounted.current) {
      setMax(RoundNum(newMax));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subTotals]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  const viewMax = klFloor(max);

  return (
    <section css={cssFlowRateMin}>
      <div className="flow-rate-min-inner">
        <ul style={{ flexGrow: 1 }}>
          {/** 縦軸：水量 */}
          <li className="v-axis v-axis-width">
            <span className="v-line max" />
            <span className="num max" style={{ right: '4px' }}>
              {viewMax}
            </span>
            <span className="v-line half" />
            <span className="num half" style={{ right: '4px' }}>
              {viewMax / 2}
            </span>
            <span className="num bottom">[{isKL ? 'KL' : 'L'}]</span>
          </li>
          <li style={{ flexGrow: 1 }}>
            <Chart
              width="100%"
              height="100%"
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={[
                [
                  { label: 'time', type: 'string' },
                  { label: 'total', type: 'number' },
                ],
                ...datas,
              ]}
              options={{
                tooltip: { trigger: 'none' },
                backgroundColor: 'transparent',
                colors: ['#57bfed'],
                chartArea: { top: 0, left: 0, right: 0, bottom: 0 },
                legend: { position: 'none' },
                isStacked: false,
                vAxis: {
                  baseline: 0,
                  minValue: 0,
                  baselineColor: '#fff',
                  gridlines: {
                    count: 0,
                  },
                  viewWindowMode: 'explicit',
                  viewWindow: {
                    max,
                    min: 0,
                  },
                },
                hAxis: {
                  baselineColor: '#FFF',
                  gridlines: {
                    count: 0,
                  },
                },
              }}
            />
          </li>
        </ul>
        <ul style={{ height: '15px' }}>
          {/** 横軸：時間 */}
          <li className="v-axis-width" />
          <li className="h-axis">
            <div className="times">
              {datas.map((item, index) => {
                const key = `FlowRateMin-${restroomId}-${index}-${item[0]}-${item[1]}`;

                if (index % 2 !== 0) {
                  return (
                    <div key={key}>
                      <span className="h-line" />
                    </div>
                  );
                }

                return (
                  <div key={key}>
                    <span className="h-line" />
                    {item[0]}
                  </div>
                );
              })}
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

const cssWaterFlow = css`
  width: 100%;
  height: 100%;
`;
/**
 * 詳細画面水流グラフ
 *
 * @param {CreateClipHgSubtotalInput[]} subTotals SubTotalデータ
 * @param {Usage} usages  トイレで使用する用途
 */
type WaterFlowProps = {
  subTotals: CreateClipHgSubtotalInput[];
  usages: Usage[];
};
export const WaterFlow: FC<WaterFlowProps> = ({ subTotals, usages }) => {
  const unmounted = useRef(false);
  const [datas, setDatas] = useState<(number | Date | null | undefined)[][]>([]);
  const size = useWindowSize();

  // KL変換
  const klFloor = (val: number | null | undefined, isKl: boolean): number => {
    if (val === null || val === undefined) {
      return 0;
    }

    // 小数点２桁まで
    return isKl ? Math.floor((val / 1000) * 100) / 100 : val;
  };

  // データ更新
  useEffect(() => {
    if (!subTotals) {
      return;
    }
    const isMale = usages.some((item) => item === 1);
    const isFemale = usages.some((item) => item === 2);
    const isMulti = usages.some((item) => item === 3);

    let isKl = false;
    for (let i = 0; i < subTotals.length; i += 1) {
      const item = subTotals[i];
      if (CheckKl(item.maleFlowRate) || CheckKl(item.femaleFlowRate) || CheckKl(item.multiFlowRate)) {
        isKl = true;
        break;
      }
    }

    const newDatas: (number | Date | null | undefined)[][] = [];
    subTotals.forEach((item) => {
      const maleFlowRate = klFloor(item.maleFlowRate, isKl);
      const femaleFlowRate = klFloor(item.femaleFlowRate, isKl);
      const multiFlowRate = klFloor(item.multiFlowRate, isKl);

      const total = (() => {
        if (!IsNum(item.maleFlowRate) && !IsNum(item.femaleFlowRate) && !IsNum(item.multiFlowRate)) {
          return null;
        }

        return (isMale ? maleFlowRate : 0) + (isFemale ? femaleFlowRate : 0) + (isMulti ? multiFlowRate : 0);
      })();

      newDatas.push([
        // parseInt(item.period.substr(-2), 10),
        dayjs(dayjs(item.period).format('YYYY-MM-DD HH:00:00')).toDate(),
        isMale && IsNum(item.maleFlowRate) ? maleFlowRate : null,
        isFemale && IsNum(item.femaleFlowRate) ? femaleFlowRate : null,
        isMulti && IsNum(item.multiFlowRate) ? multiFlowRate : null,
        total,
      ]);
    });

    if (!unmounted.current) {
      setDatas(newDatas);
    }
  }, [subTotals, usages]);

  useEffect(() => {
    return () => {
      unmounted.current = true;
    };
  }, []);

  return (
    <section key={`WaterFlow_${size.height}_${size.width}`} css={cssWaterFlow}>
      <Chart
        width="100%"
        height="100%"
        chartType="LineChart"
        loader={<div>Loading Chart</div>}
        data={[
          [
            { label: 'time', type: 'date' },
            { label: 'male', type: 'number' },
            { label: 'female', type: 'number' },
            { label: 'multi', type: 'number' },
            { label: 'total', type: 'number' },
          ],
          ...datas,
        ]}
        options={{
          tooltip: { trigger: 'none' },
          series: {
            0: { type: 'line', color: '#43a1ff', lineDashStyle: [6, 6] },
            1: { type: 'line', color: '#d61f58', lineDashStyle: [2, 2] },
            2: { type: 'line', color: '#8bcc0c', lineDashStyle: [2, 2, 7, 2, 7, 2] },
            3: { type: 'line', color: '#e36214' },
          },
          backgroundColor: 'transparent',
          chartArea: { height: '80%', width: '90%' },
          legend: { position: 'none' },
          isStacked: false,
          vAxis: {
            minValue: 0,
            gridlines: {
              count: 5,
              color: '#515863',
            },
            baselineColor: '#fff',
            textStyle: {
              color: '#FFF',
            },
            minorGridlines: {
              count: 0,
            },
            viewWindowMode: 'maximized',
          },
          hAxis: {
            format: 'H',
            baselineColor: '#a9e7e5',
            textStyle: {
              color: '#FFF',
            },
            gridlines: {
              count: 24,
              color: '#a9e7e5',
            },
            minorGridlines: {
              count: 0,
            },
          },
        }}
      />
    </section>
  );
};

const cssWashUseRateScaleColor = '#ffffff';
const cssWashUseRateScale = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
  font-size: 11px;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .bar-area {
    position: relative;
    flex-grow: 1;

    .outer {
      position: absolute;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
    }
  }

  .icons {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 60px;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 90%;
      -webkit-transform: translateY(-50%) translateX(-50%);
      transform: translateY(-50%) translateX(-50%);
    }
  }

  .background {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    .max {
      top: -10px;
    }

    .min {
      bottom: -9px;
    }

    .line {
      border-top: solid 1px ${cssWashUseRateScaleColor};
      border-bottom: solid 1px ${cssWashUseRateScaleColor};
    }

    ul {
      display: flex;
      flex-direction: column;
      height: 100%;

      li {
        position: relative;
        display: flex;
        flex-grow: 1;
        align-items: center;

        div {
          width: 100%;
          height: 1px;
          border-bottom: solid 1px ${cssWashUseRateScaleColor};
        }

        span {
          position: absolute;
          right: 3px;
          color: ${cssWashUseRateScaleColor};
        }
      }
    }
  }

  .bars {
    display: flex;
    flex-direction: row;

    .bar {
      display: flex;
      flex-direction: column;
      flex-grow: 0.5;
      font-size: 12px;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .toilet-wash {
        background-color: #00a2b8;
      }

      .toilet {
        background-color: #ffc540;
      }

      .wash {
        background-color: #d9d5d5;
      }
    }
  }

  .space {
    flex-grow: 0.5;
  }

  .left {
    width: 30px;
  }

  .right {
    flex-grow: 1;
  }
`;

/**
 * 手洗い利用割合
 * 拡大縮小が可能なVer
 */
export const WashUseRateScale: FC = () => {
  const nums = [80, 60, 40, 20];

  // 背景
  const Background = () => {
    return (
      <article className="background">
        <ul className="left">
          <li style={{ flexGrow: 0.5 }}>
            <span className="max">100</span>
          </li>
          {nums.map((num) => {
            return (
              <li key={`WashUseRateScale_graph-background-nums-${num}`}>
                <span>{num}</span>
              </li>
            );
          })}
          <li style={{ flexGrow: 0.5 }}>
            <span className="min">0</span>
          </li>
        </ul>
        <ul className="right line">
          <li style={{ flexGrow: 0.5 }} />
          {nums.map((num) => {
            return (
              <li key={`WashUseRateScale_graph-background-lines-${num}`}>
                <div />
              </li>
            );
          })}
          <li style={{ flexGrow: 0.5 }} />
        </ul>
      </article>
    );
  };

  // 棒グラフ
  const BarGraph = (wash: number, toilet: number, toiletWash: number) => {
    const hideCount = 10;

    return (
      <React.Fragment>
        <ul className="bar">
          <li className="wash" style={{ height: `${wash}%` }}>
            {wash >= hideCount && <span>{wash}%</span>}
          </li>
          <li className="toilet" style={{ height: `${toilet}%` }}>
            {toilet >= hideCount && <span>{toilet}%</span>}
          </li>
          <li className="toilet-wash" style={{ height: `${toiletWash}%` }}>
            {toiletWash >= hideCount && <span>{toiletWash}%</span>}
          </li>
        </ul>
        <div className="space" />
      </React.Fragment>
    );
  };

  // アイコン
  const Icon = (src: string) => {
    return (
      <React.Fragment>
        <ul className="bar">
          <li style={{ height: '100%', position: 'relative' }}>
            <img className="icon" src={src} alt="icon" />
          </li>
        </ul>
        <div className="space" />
      </React.Fragment>
    );
  };

  return (
    <section css={cssWashUseRateScale}>
      <div className="bar-area">
        {/* 背景 */}
        <Background />
        {/* グラフバー */}
        <ul className="outer">
          <li className="left" />
          <li className="right bars">
            <div className="space" />
            {BarGraph(10, 10, 80)}
            {BarGraph(40, 40, 20)}
            {BarGraph(40, 40, 20)}
            {BarGraph(0, 100, 0)}
          </li>
        </ul>
      </div>
      {/* アイコン */}
      <ul className="icons">
        <li className="left" />
        <li className="right bars">
          <div className="space" />
          {Icon(iconWashUseRateTotal)}
          {Icon(iconWashUseRateMan)}
          {Icon(iconWashUseRateWomen)}
          {Icon(iconWashUseRateMulti)}
        </li>
      </ul>
    </section>
  );
};

export const Test: FC = () => {
  return (
    <section css={cssMain}>
      <ul className="main-list">
        <li className="WashUseRateScaleOuter">
          <h2>手洗い利用割合</h2>
          <div className="sample-graph">
            <WashUseRateScale />
          </div>
        </li>
      </ul>
    </section>
  );
};
