import { createContext, useContext } from 'react';

export type User = {
  username: string;
  attributes: {
    'custom:map_coordinate': string | undefined;
    'custom:map_zoom_level': number | undefined;
    'custom:disp_tw_zoom_level': number | undefined;
    email: string | undefined;
  };
  isAdmin?: boolean;
};

export type AuthData = User & {
  signInUserSession?: {
    accessToken?: {
      payload?: {
        'cognito:groups'?: string[];
      };
    };
  };
};

export type UserContextType = {
  user: User | undefined;
  setUser: (newData: User) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateUser: (authData?: any) => void;
};

export const UserContext = createContext<UserContextType>({} as UserContextType);

export const useAuth = (): UserContextType => useContext(UserContext);
