/**
 * トイレデータの保持
 */
import { createContext, useContext } from 'react';
import { CreateClipHgDeviceInput, CreateClipHgRestroomInput, ClipHgArea } from 'API';

export type StorageItems = {
  key?: string;
  size?: number;
};

export type RestroomItems = CreateClipHgRestroomInput & {
  restroomId: string;
  devices: {
    items: CreateClipHgDeviceInput[];
  };
  images: StorageItems[];
  pref?: ClipHgArea;
  city?: ClipHgArea;
  ward?: ClipHgArea;
  isNotify?: boolean;
  isHiddenSecurityWarning?: boolean;
};

type RestroomContextParams = {
  restroom: RestroomItems | undefined;
  setRestroom: (newRestroom: RestroomItems | undefined) => void;
  Update: (retroomId?: string) => Promise<boolean>;
};

export const RestroomContext = createContext<RestroomContextParams>({} as RestroomContextParams);
export const useRestroom = (): RestroomContextParams => useContext(RestroomContext);
