/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateClipHgRestroomInput = {
  prefAreaId?: string | null,
  cityAreaId?: string | null,
  wardAreaId?: string | null,
  name: string,
  address: string,
  location: LocationInput,
  latestLocationTime?: string | null,
  isWinter: boolean,
  isPremium?: boolean | null,
  useMin: number,
  useMax: number,
  topPosition?: number | null,
  leftPosition?: number | null,
  isMove?: boolean | null,
};

export type LocationInput = {
  lat: number,
  lon: number,
};

export type ModelClipHgRestroomConditionInput = {
  prefAreaId?: ModelIDInput | null,
  cityAreaId?: ModelIDInput | null,
  wardAreaId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  latestLocationTime?: ModelStringInput | null,
  isWinter?: ModelBooleanInput | null,
  isPremium?: ModelBooleanInput | null,
  useMin?: ModelIntInput | null,
  useMax?: ModelIntInput | null,
  topPosition?: ModelIntInput | null,
  leftPosition?: ModelIntInput | null,
  isMove?: ModelBooleanInput | null,
  and?: Array< ModelClipHgRestroomConditionInput | null > | null,
  or?: Array< ModelClipHgRestroomConditionInput | null > | null,
  not?: ModelClipHgRestroomConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ClipHgRestroom = {
  __typename: "ClipHgRestroom",
  restroomId: string,
  prefAreaId?: string | null,
  cityAreaId?: string | null,
  wardAreaId?: string | null,
  name: string,
  address: string,
  location: Location,
  latestLocationTime?: string | null,
  isWinter: boolean,
  isPremium?: boolean | null,
  useMin: number,
  useMax: number,
  topPosition?: number | null,
  leftPosition?: number | null,
  isMove?: boolean | null,
  pref?: ClipHgArea | null,
  city?: ClipHgArea | null,
  ward?: ClipHgArea | null,
  createdAt: string,
  updatedAt: string,
  devices?: ModelClipHgDeviceConnection | null,
};

export type Location = {
  __typename: "Location",
  lat: number,
  lon: number,
};

export type ClipHgArea = {
  __typename: "ClipHgArea",
  areaId: string,
  name: string,
  location: Location,
  createdAt: string,
  updatedAt: string,
};

export type ModelClipHgDeviceConnection = {
  __typename: "ModelClipHgDeviceConnection",
  items:  Array<ClipHgDevice | null >,
  nextToken?: string | null,
};

export type ClipHgDevice = {
  __typename: "ClipHgDevice",
  deviceId: string,
  restroomId: string,
  co2SensorUsage?: number | null,
  createdAt: string,
  updatedAt: string,
  restroom?: ClipHgRestroom | null,
  toilets?: ModelClipHgToiletConnection | null,
};

export type ModelClipHgToiletConnection = {
  __typename: "ModelClipHgToiletConnection",
  items:  Array<ClipHgToilet | null >,
  nextToken?: string | null,
};

export type ClipHgToilet = {
  __typename: "ClipHgToilet",
  deviceId: string,
  name: string,
  usage: number,
  number: number,
  type: number,
  fixedFlowRate?: number | null,
  doorLockId?: string | null,
  isOccupied?: boolean | null,
  emergencyButtonId?: string | null,
  isConnected?: boolean | null,
  disconnectedTime?: string | null,
  hasDisconnectWarning?: boolean | null,
  createdAt: string,
  updatedAt: string,
};

export type DeleteClipHgRestroomInput = {
  restroomId: string,
};

export type CreateClipHgDeviceInput = {
  deviceId: string,
  restroomId: string,
  co2SensorUsage?: number | null,
};

export type ModelClipHgDeviceConditionInput = {
  restroomId?: ModelIDInput | null,
  co2SensorUsage?: ModelIntInput | null,
  and?: Array< ModelClipHgDeviceConditionInput | null > | null,
  or?: Array< ModelClipHgDeviceConditionInput | null > | null,
  not?: ModelClipHgDeviceConditionInput | null,
};

export type UpdateClipHgDeviceInput = {
  deviceId: string,
  restroomId?: string | null,
  co2SensorUsage?: number | null,
};

export type DeleteClipHgDeviceInput = {
  deviceId: string,
};

export type CreateClipHgToiletInput = {
  deviceId: string,
  name: string,
  usage: number,
  number: number,
  type: number,
  fixedFlowRate?: number | null,
  doorLockId?: string | null,
  isOccupied?: boolean | null,
  emergencyButtonId?: string | null,
  isConnected?: boolean | null,
  disconnectedTime?: string | null,
  hasDisconnectWarning?: boolean | null,
};

export type ModelClipHgToiletConditionInput = {
  name?: ModelStringInput | null,
  usage?: ModelIntInput | null,
  type?: ModelIntInput | null,
  fixedFlowRate?: ModelFloatInput | null,
  doorLockId?: ModelStringInput | null,
  isOccupied?: ModelBooleanInput | null,
  emergencyButtonId?: ModelStringInput | null,
  isConnected?: ModelBooleanInput | null,
  disconnectedTime?: ModelStringInput | null,
  hasDisconnectWarning?: ModelBooleanInput | null,
  and?: Array< ModelClipHgToiletConditionInput | null > | null,
  or?: Array< ModelClipHgToiletConditionInput | null > | null,
  not?: ModelClipHgToiletConditionInput | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type DeleteClipHgToiletInput = {
  deviceId: string,
  number: number,
};

export type DeleteClipHgCalcDataInput = {
  calcDataId: string,
};

export type ModelClipHgCalcDataConditionInput = {
  restroomId?: ModelStringInput | null,
  deviceId?: ModelStringInput | null,
  sendDateTime?: ModelStringInput | null,
  carbonDioxide?: ModelFloatInput | null,
  temperature?: ModelFloatInput | null,
  humidity?: ModelFloatInput | null,
  usage?: ModelIntInput | null,
  number?: ModelIntInput | null,
  type?: ModelIntInput | null,
  flushCount?: ModelIntInput | null,
  flowTime?: ModelIntInput | null,
  flowRate?: ModelFloatInput | null,
  ev?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClipHgCalcDataConditionInput | null > | null,
  or?: Array< ModelClipHgCalcDataConditionInput | null > | null,
  not?: ModelClipHgCalcDataConditionInput | null,
};

export type ClipHgCalcData = {
  __typename: "ClipHgCalcData",
  calcDataId: string,
  restroomId: string,
  deviceId: string,
  sendDateTime: string,
  carbonDioxide?: number | null,
  temperature?: number | null,
  humidity?: number | null,
  usage?: number | null,
  number?: number | null,
  type: number,
  flushCount?: number | null,
  flowTime?: number | null,
  flowRate?: number | null,
  ev?: string | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type DeleteClipHgUseCountInput = {
  useCountId: string,
};

export type ModelClipHgUseCountConditionInput = {
  restroomId?: ModelStringInput | null,
  deviceId?: ModelStringInput | null,
  useDateTime?: ModelStringInput | null,
  usage?: ModelIntInput | null,
  type?: ModelIntInput | null,
  number?: ModelIntInput | null,
  washDeadline?: ModelStringInput | null,
  washDeviceId?: ModelStringInput | null,
  washNumber?: ModelIntInput | null,
  washTime?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelClipHgUseCountConditionInput | null > | null,
  or?: Array< ModelClipHgUseCountConditionInput | null > | null,
  not?: ModelClipHgUseCountConditionInput | null,
};

export type ClipHgUseCount = {
  __typename: "ClipHgUseCount",
  useCountId: string,
  restroomId: string,
  deviceId: string,
  useDateTime: string,
  usage: number,
  type: number,
  number: number,
  washDeadline?: string | null,
  washDeviceId?: string | null,
  washNumber?: number | null,
  washTime?: string | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type DeleteClipHgSubtotalInput = {
  restroomId: string,
  period: string,
};

export type ModelClipHgSubtotalConditionInput = {
  maleBothUse?: ModelIntInput | null,
  maleToiletUse?: ModelIntInput | null,
  maleWashUse?: ModelIntInput | null,
  femaleBothUse?: ModelIntInput | null,
  femaleToiletUse?: ModelIntInput | null,
  femaleWashUse?: ModelIntInput | null,
  multiBothUse?: ModelIntInput | null,
  multiToiletUse?: ModelIntInput | null,
  multiWashUse?: ModelIntInput | null,
  maleCarbonDioxide?: ModelFloatInput | null,
  femaleCarbonDioxide?: ModelFloatInput | null,
  multiCarbonDioxide?: ModelFloatInput | null,
  latestTemperature?: ModelFloatInput | null,
  latestHumidity?: ModelFloatInput | null,
  maleFlowRate?: ModelFloatInput | null,
  femaleFlowRate?: ModelFloatInput | null,
  multiFlowRate?: ModelFloatInput | null,
  average?: ModelIntInput | null,
  elapsedDays?: ModelIntInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelClipHgSubtotalConditionInput | null > | null,
  or?: Array< ModelClipHgSubtotalConditionInput | null > | null,
  not?: ModelClipHgSubtotalConditionInput | null,
};

export type ClipHgSubtotal = {
  __typename: "ClipHgSubtotal",
  restroomId: string,
  period: string,
  maleBothUse: number,
  maleToiletUse: number,
  maleWashUse: number,
  femaleBothUse: number,
  femaleToiletUse: number,
  femaleWashUse: number,
  multiBothUse: number,
  multiToiletUse: number,
  multiWashUse: number,
  maleCarbonDioxide?: number | null,
  femaleCarbonDioxide?: number | null,
  multiCarbonDioxide?: number | null,
  latestTemperature?: number | null,
  latestHumidity?: number | null,
  maleFlowRate?: number | null,
  femaleFlowRate?: number | null,
  multiFlowRate?: number | null,
  average?: number | null,
  elapsedDays?: number | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type CreateClipHgAreaInput = {
  areaId: string,
  name: string,
  location: LocationInput,
};

export type ModelClipHgAreaConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelClipHgAreaConditionInput | null > | null,
  or?: Array< ModelClipHgAreaConditionInput | null > | null,
  not?: ModelClipHgAreaConditionInput | null,
};

export type UpdateClipHgAreaInput = {
  areaId: string,
  name?: string | null,
  location?: LocationInput | null,
};

export type DeleteClipHgAreaInput = {
  areaId: string,
};

export type CreateClipHgUserRestroomInput = {
  user: string,
  restroomId: string,
  isNotify?: boolean | null,
  isHiddenSecurityWarning?: boolean | null,
};

export type ModelClipHgUserRestroomConditionInput = {
  isNotify?: ModelBooleanInput | null,
  isHiddenSecurityWarning?: ModelBooleanInput | null,
  and?: Array< ModelClipHgUserRestroomConditionInput | null > | null,
  or?: Array< ModelClipHgUserRestroomConditionInput | null > | null,
  not?: ModelClipHgUserRestroomConditionInput | null,
};

export type ClipHgUserRestroom = {
  __typename: "ClipHgUserRestroom",
  user: string,
  restroomId: string,
  isNotify?: boolean | null,
  isHiddenSecurityWarning?: boolean | null,
  createdAt: string,
  updatedAt: string,
  restrooms?: ModelClipHgRestroomConnection | null,
};

export type ModelClipHgRestroomConnection = {
  __typename: "ModelClipHgRestroomConnection",
  items:  Array<ClipHgRestroom | null >,
  nextToken?: string | null,
};

export type UpdateClipHgUserRestroomInput = {
  user: string,
  restroomId: string,
  isNotify?: boolean | null,
  isHiddenSecurityWarning?: boolean | null,
};

export type DeleteClipHgUserRestroomInput = {
  user: string,
  restroomId: string,
};

export type UpdateClipHgRestroomInput = {
  restroomId: string,
  prefAreaId?: string | null,
  cityAreaId?: string | null,
  wardAreaId?: string | null,
  name?: string | null,
  address?: string | null,
  location?: LocationInput | null,
  latestLocationTime?: string | null,
  isWinter?: boolean | null,
  isPremium?: boolean | null,
  useMin?: number | null,
  useMax?: number | null,
  topPosition?: number | null,
  leftPosition?: number | null,
  isMove?: boolean | null,
};

export type UpdateClipHgToiletInput = {
  deviceId: string,
  name?: string | null,
  usage?: number | null,
  number: number,
  type?: number | null,
  fixedFlowRate?: number | null,
  doorLockId?: string | null,
  isOccupied?: boolean | null,
  emergencyButtonId?: string | null,
  isConnected?: boolean | null,
  disconnectedTime?: string | null,
  hasDisconnectWarning?: boolean | null,
};

export type CreateClipHgCalcDataInput = {
  restroomId: string,
  deviceId: string,
  sendDateTime: string,
  carbonDioxide?: number | null,
  temperature?: number | null,
  humidity?: number | null,
  usage?: number | null,
  number?: number | null,
  type: number,
  flushCount?: number | null,
  flowTime?: number | null,
  flowRate?: number | null,
  ev?: string | null,
  ttl?: number | null,
};

export type UpdateClipHgCalcDataInput = {
  calcDataId: string,
  restroomId?: string | null,
  deviceId?: string | null,
  sendDateTime?: string | null,
  carbonDioxide?: number | null,
  temperature?: number | null,
  humidity?: number | null,
  usage?: number | null,
  number?: number | null,
  type?: number | null,
  flushCount?: number | null,
  flowTime?: number | null,
  flowRate?: number | null,
  ev?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type CreateClipHgWarningInput = {
  restroomId: string,
  deviceId?: string | null,
  sendDateTime: string,
  warningNumber: number,
  warningType?: number | null,
  usage?: number | null,
  number?: number | null,
  type?: number | null,
  toiletName?: string | null,
  ttl?: number | null,
};

export type ModelClipHgWarningConditionInput = {
  restroomId?: ModelStringInput | null,
  deviceId?: ModelStringInput | null,
  sendDateTime?: ModelStringInput | null,
  warningNumber?: ModelIntInput | null,
  warningType?: ModelIntInput | null,
  usage?: ModelIntInput | null,
  number?: ModelIntInput | null,
  type?: ModelIntInput | null,
  toiletName?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClipHgWarningConditionInput | null > | null,
  or?: Array< ModelClipHgWarningConditionInput | null > | null,
  not?: ModelClipHgWarningConditionInput | null,
};

export type ClipHgWarning = {
  __typename: "ClipHgWarning",
  warningId: string,
  restroomId: string,
  deviceId?: string | null,
  sendDateTime: string,
  warningNumber: number,
  warningType?: number | null,
  usage?: number | null,
  number?: number | null,
  type?: number | null,
  toiletName?: string | null,
  ttl?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateClipHgWarningInput = {
  warningId: string,
  restroomId?: string | null,
  deviceId?: string | null,
  sendDateTime?: string | null,
  warningNumber?: number | null,
  warningType?: number | null,
  usage?: number | null,
  number?: number | null,
  type?: number | null,
  toiletName?: string | null,
  ttl?: number | null,
  createdAt?: string | null,
  updatedAt?: string | null,
};

export type DeleteClipHgWarningInput = {
  warningId: string,
};

export type CreateClipHgUseCountInput = {
  restroomId: string,
  deviceId: string,
  useDateTime: string,
  usage: number,
  type: number,
  number: number,
  washDeadline?: string | null,
  washDeviceId?: string | null,
  washNumber?: number | null,
  washTime?: string | null,
  ttl?: number | null,
};

export type UpdateClipHgUseCountInput = {
  useCountId: string,
  restroomId?: string | null,
  deviceId?: string | null,
  useDateTime?: string | null,
  usage?: number | null,
  type?: number | null,
  number?: number | null,
  washDeadline?: string | null,
  washDeviceId?: string | null,
  washNumber?: number | null,
  washTime?: string | null,
  ttl?: number | null,
};

export type CreateClipHgSubtotalInput = {
  restroomId: string,
  period: string,
  maleBothUse: number,
  maleToiletUse: number,
  maleWashUse: number,
  femaleBothUse: number,
  femaleToiletUse: number,
  femaleWashUse: number,
  multiBothUse: number,
  multiToiletUse: number,
  multiWashUse: number,
  maleCarbonDioxide?: number | null,
  femaleCarbonDioxide?: number | null,
  multiCarbonDioxide?: number | null,
  latestTemperature?: number | null,
  latestHumidity?: number | null,
  maleFlowRate?: number | null,
  femaleFlowRate?: number | null,
  multiFlowRate?: number | null,
  average?: number | null,
  elapsedDays?: number | null,
  ttl?: number | null,
};

export type UpdateClipHgSubtotalInput = {
  restroomId: string,
  period: string,
  maleBothUse?: number | null,
  maleToiletUse?: number | null,
  maleWashUse?: number | null,
  femaleBothUse?: number | null,
  femaleToiletUse?: number | null,
  femaleWashUse?: number | null,
  multiBothUse?: number | null,
  multiToiletUse?: number | null,
  multiWashUse?: number | null,
  maleCarbonDioxide?: number | null,
  femaleCarbonDioxide?: number | null,
  multiCarbonDioxide?: number | null,
  latestTemperature?: number | null,
  latestHumidity?: number | null,
  maleFlowRate?: number | null,
  femaleFlowRate?: number | null,
  multiFlowRate?: number | null,
  average?: number | null,
  elapsedDays?: number | null,
  ttl?: number | null,
};

export type ModelClipHgAreaFilterInput = {
  areaId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelClipHgAreaFilterInput | null > | null,
  or?: Array< ModelClipHgAreaFilterInput | null > | null,
  not?: ModelClipHgAreaFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelClipHgAreaConnection = {
  __typename: "ModelClipHgAreaConnection",
  items:  Array<ClipHgArea | null >,
  nextToken?: string | null,
};

export type ModelClipHgRestroomFilterInput = {
  restroomId?: ModelIDInput | null,
  prefAreaId?: ModelIDInput | null,
  cityAreaId?: ModelIDInput | null,
  wardAreaId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  address?: ModelStringInput | null,
  latestLocationTime?: ModelStringInput | null,
  isWinter?: ModelBooleanInput | null,
  isPremium?: ModelBooleanInput | null,
  useMin?: ModelIntInput | null,
  useMax?: ModelIntInput | null,
  topPosition?: ModelIntInput | null,
  leftPosition?: ModelIntInput | null,
  isMove?: ModelBooleanInput | null,
  and?: Array< ModelClipHgRestroomFilterInput | null > | null,
  or?: Array< ModelClipHgRestroomFilterInput | null > | null,
  not?: ModelClipHgRestroomFilterInput | null,
};

export type ModelClipHgDeviceFilterInput = {
  deviceId?: ModelIDInput | null,
  restroomId?: ModelIDInput | null,
  co2SensorUsage?: ModelIntInput | null,
  and?: Array< ModelClipHgDeviceFilterInput | null > | null,
  or?: Array< ModelClipHgDeviceFilterInput | null > | null,
  not?: ModelClipHgDeviceFilterInput | null,
};

export type ModelIntKeyConditionInput = {
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelClipHgToiletFilterInput = {
  deviceId?: ModelIDInput | null,
  name?: ModelStringInput | null,
  usage?: ModelIntInput | null,
  number?: ModelIntInput | null,
  type?: ModelIntInput | null,
  fixedFlowRate?: ModelFloatInput | null,
  doorLockId?: ModelStringInput | null,
  isOccupied?: ModelBooleanInput | null,
  emergencyButtonId?: ModelStringInput | null,
  isConnected?: ModelBooleanInput | null,
  disconnectedTime?: ModelStringInput | null,
  hasDisconnectWarning?: ModelBooleanInput | null,
  and?: Array< ModelClipHgToiletFilterInput | null > | null,
  or?: Array< ModelClipHgToiletFilterInput | null > | null,
  not?: ModelClipHgToiletFilterInput | null,
};

export type ModelClipHgCalcDataFilterInput = {
  calcDataId?: ModelIDInput | null,
  restroomId?: ModelStringInput | null,
  deviceId?: ModelStringInput | null,
  sendDateTime?: ModelStringInput | null,
  carbonDioxide?: ModelFloatInput | null,
  temperature?: ModelFloatInput | null,
  humidity?: ModelFloatInput | null,
  usage?: ModelIntInput | null,
  number?: ModelIntInput | null,
  type?: ModelIntInput | null,
  flushCount?: ModelIntInput | null,
  flowTime?: ModelIntInput | null,
  flowRate?: ModelFloatInput | null,
  ev?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClipHgCalcDataFilterInput | null > | null,
  or?: Array< ModelClipHgCalcDataFilterInput | null > | null,
  not?: ModelClipHgCalcDataFilterInput | null,
};

export type ModelClipHgCalcDataConnection = {
  __typename: "ModelClipHgCalcDataConnection",
  items:  Array<ClipHgCalcData | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelClipHgCalcDataSortByTypeCompositeKeyConditionInput = {
  eq?: ModelClipHgCalcDataSortByTypeCompositeKeyInput | null,
  le?: ModelClipHgCalcDataSortByTypeCompositeKeyInput | null,
  lt?: ModelClipHgCalcDataSortByTypeCompositeKeyInput | null,
  ge?: ModelClipHgCalcDataSortByTypeCompositeKeyInput | null,
  gt?: ModelClipHgCalcDataSortByTypeCompositeKeyInput | null,
  between?: Array< ModelClipHgCalcDataSortByTypeCompositeKeyInput | null > | null,
  beginsWith?: ModelClipHgCalcDataSortByTypeCompositeKeyInput | null,
};

export type ModelClipHgCalcDataSortByTypeCompositeKeyInput = {
  type?: number | null,
  sendDateTime?: string | null,
};

export type ModelClipHgWarningFilterInput = {
  warningId?: ModelIDInput | null,
  restroomId?: ModelStringInput | null,
  deviceId?: ModelStringInput | null,
  sendDateTime?: ModelStringInput | null,
  warningNumber?: ModelIntInput | null,
  warningType?: ModelIntInput | null,
  usage?: ModelIntInput | null,
  number?: ModelIntInput | null,
  type?: ModelIntInput | null,
  toiletName?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  and?: Array< ModelClipHgWarningFilterInput | null > | null,
  or?: Array< ModelClipHgWarningFilterInput | null > | null,
  not?: ModelClipHgWarningFilterInput | null,
};

export type ModelClipHgWarningConnection = {
  __typename: "ModelClipHgWarningConnection",
  items:  Array<ClipHgWarning | null >,
  nextToken?: string | null,
};

export type ModelClipHgUseCountFilterInput = {
  useCountId?: ModelIDInput | null,
  restroomId?: ModelStringInput | null,
  deviceId?: ModelStringInput | null,
  useDateTime?: ModelStringInput | null,
  usage?: ModelIntInput | null,
  type?: ModelIntInput | null,
  number?: ModelIntInput | null,
  washDeadline?: ModelStringInput | null,
  washDeviceId?: ModelStringInput | null,
  washNumber?: ModelIntInput | null,
  washTime?: ModelStringInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelClipHgUseCountFilterInput | null > | null,
  or?: Array< ModelClipHgUseCountFilterInput | null > | null,
  not?: ModelClipHgUseCountFilterInput | null,
};

export type ModelClipHgUseCountConnection = {
  __typename: "ModelClipHgUseCountConnection",
  items:  Array<ClipHgUseCount | null >,
  nextToken?: string | null,
};

export type ModelClipHgSubtotalFilterInput = {
  restroomId?: ModelStringInput | null,
  period?: ModelStringInput | null,
  maleBothUse?: ModelIntInput | null,
  maleToiletUse?: ModelIntInput | null,
  maleWashUse?: ModelIntInput | null,
  femaleBothUse?: ModelIntInput | null,
  femaleToiletUse?: ModelIntInput | null,
  femaleWashUse?: ModelIntInput | null,
  multiBothUse?: ModelIntInput | null,
  multiToiletUse?: ModelIntInput | null,
  multiWashUse?: ModelIntInput | null,
  maleCarbonDioxide?: ModelFloatInput | null,
  femaleCarbonDioxide?: ModelFloatInput | null,
  multiCarbonDioxide?: ModelFloatInput | null,
  latestTemperature?: ModelFloatInput | null,
  latestHumidity?: ModelFloatInput | null,
  maleFlowRate?: ModelFloatInput | null,
  femaleFlowRate?: ModelFloatInput | null,
  multiFlowRate?: ModelFloatInput | null,
  average?: ModelIntInput | null,
  elapsedDays?: ModelIntInput | null,
  ttl?: ModelIntInput | null,
  and?: Array< ModelClipHgSubtotalFilterInput | null > | null,
  or?: Array< ModelClipHgSubtotalFilterInput | null > | null,
  not?: ModelClipHgSubtotalFilterInput | null,
};

export type ModelClipHgSubtotalConnection = {
  __typename: "ModelClipHgSubtotalConnection",
  items:  Array<ClipHgSubtotal | null >,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelClipHgUserRestroomFilterInput = {
  user?: ModelStringInput | null,
  restroomId?: ModelIDInput | null,
  isNotify?: ModelBooleanInput | null,
  isHiddenSecurityWarning?: ModelBooleanInput | null,
  and?: Array< ModelClipHgUserRestroomFilterInput | null > | null,
  or?: Array< ModelClipHgUserRestroomFilterInput | null > | null,
  not?: ModelClipHgUserRestroomFilterInput | null,
};

export type ModelClipHgUserRestroomConnection = {
  __typename: "ModelClipHgUserRestroomConnection",
  items:  Array<ClipHgUserRestroom | null >,
  nextToken?: string | null,
};

export type CreateClipHgRestroomMutationVariables = {
  input: CreateClipHgRestroomInput,
  condition?: ModelClipHgRestroomConditionInput | null,
};

export type CreateClipHgRestroomMutation = {
  createClipHgRestroom?:  {
    __typename: "ClipHgRestroom",
    restroomId: string,
    prefAreaId?: string | null,
    cityAreaId?: string | null,
    wardAreaId?: string | null,
    name: string,
    address: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    latestLocationTime?: string | null,
    isWinter: boolean,
    isPremium?: boolean | null,
    useMin: number,
    useMax: number,
    topPosition?: number | null,
    leftPosition?: number | null,
    isMove?: boolean | null,
    pref?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    city?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    ward?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelClipHgDeviceConnection",
      items:  Array< {
        __typename: "ClipHgDevice",
        deviceId: string,
        restroomId: string,
        co2SensorUsage?: number | null,
        createdAt: string,
        updatedAt: string,
        restroom?:  {
          __typename: "ClipHgRestroom",
          restroomId: string,
          prefAreaId?: string | null,
          cityAreaId?: string | null,
          wardAreaId?: string | null,
          name: string,
          address: string,
          latestLocationTime?: string | null,
          isWinter: boolean,
          isPremium?: boolean | null,
          useMin: number,
          useMax: number,
          topPosition?: number | null,
          leftPosition?: number | null,
          isMove?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        toilets?:  {
          __typename: "ModelClipHgToiletConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteClipHgRestroomMutationVariables = {
  input: DeleteClipHgRestroomInput,
  condition?: ModelClipHgRestroomConditionInput | null,
};

export type DeleteClipHgRestroomMutation = {
  deleteClipHgRestroom?:  {
    __typename: "ClipHgRestroom",
    restroomId: string,
    prefAreaId?: string | null,
    cityAreaId?: string | null,
    wardAreaId?: string | null,
    name: string,
    address: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    latestLocationTime?: string | null,
    isWinter: boolean,
    isPremium?: boolean | null,
    useMin: number,
    useMax: number,
    topPosition?: number | null,
    leftPosition?: number | null,
    isMove?: boolean | null,
    pref?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    city?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    ward?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelClipHgDeviceConnection",
      items:  Array< {
        __typename: "ClipHgDevice",
        deviceId: string,
        restroomId: string,
        co2SensorUsage?: number | null,
        createdAt: string,
        updatedAt: string,
        restroom?:  {
          __typename: "ClipHgRestroom",
          restroomId: string,
          prefAreaId?: string | null,
          cityAreaId?: string | null,
          wardAreaId?: string | null,
          name: string,
          address: string,
          latestLocationTime?: string | null,
          isWinter: boolean,
          isPremium?: boolean | null,
          useMin: number,
          useMax: number,
          topPosition?: number | null,
          leftPosition?: number | null,
          isMove?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        toilets?:  {
          __typename: "ModelClipHgToiletConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateClipHgDeviceMutationVariables = {
  input: CreateClipHgDeviceInput,
  condition?: ModelClipHgDeviceConditionInput | null,
};

export type CreateClipHgDeviceMutation = {
  createClipHgDevice?:  {
    __typename: "ClipHgDevice",
    deviceId: string,
    restroomId: string,
    co2SensorUsage?: number | null,
    createdAt: string,
    updatedAt: string,
    restroom?:  {
      __typename: "ClipHgRestroom",
      restroomId: string,
      prefAreaId?: string | null,
      cityAreaId?: string | null,
      wardAreaId?: string | null,
      name: string,
      address: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      latestLocationTime?: string | null,
      isWinter: boolean,
      isPremium?: boolean | null,
      useMin: number,
      useMax: number,
      topPosition?: number | null,
      leftPosition?: number | null,
      isMove?: boolean | null,
      pref?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      city?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      ward?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelClipHgDeviceConnection",
        items:  Array< {
          __typename: "ClipHgDevice",
          deviceId: string,
          restroomId: string,
          co2SensorUsage?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    toilets?:  {
      __typename: "ModelClipHgToiletConnection",
      items:  Array< {
        __typename: "ClipHgToilet",
        deviceId: string,
        name: string,
        usage: number,
        number: number,
        type: number,
        fixedFlowRate?: number | null,
        doorLockId?: string | null,
        isOccupied?: boolean | null,
        emergencyButtonId?: string | null,
        isConnected?: boolean | null,
        disconnectedTime?: string | null,
        hasDisconnectWarning?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateClipHgDeviceMutationVariables = {
  input: UpdateClipHgDeviceInput,
  condition?: ModelClipHgDeviceConditionInput | null,
};

export type UpdateClipHgDeviceMutation = {
  updateClipHgDevice?:  {
    __typename: "ClipHgDevice",
    deviceId: string,
    restroomId: string,
    co2SensorUsage?: number | null,
    createdAt: string,
    updatedAt: string,
    restroom?:  {
      __typename: "ClipHgRestroom",
      restroomId: string,
      prefAreaId?: string | null,
      cityAreaId?: string | null,
      wardAreaId?: string | null,
      name: string,
      address: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      latestLocationTime?: string | null,
      isWinter: boolean,
      isPremium?: boolean | null,
      useMin: number,
      useMax: number,
      topPosition?: number | null,
      leftPosition?: number | null,
      isMove?: boolean | null,
      pref?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      city?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      ward?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelClipHgDeviceConnection",
        items:  Array< {
          __typename: "ClipHgDevice",
          deviceId: string,
          restroomId: string,
          co2SensorUsage?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    toilets?:  {
      __typename: "ModelClipHgToiletConnection",
      items:  Array< {
        __typename: "ClipHgToilet",
        deviceId: string,
        name: string,
        usage: number,
        number: number,
        type: number,
        fixedFlowRate?: number | null,
        doorLockId?: string | null,
        isOccupied?: boolean | null,
        emergencyButtonId?: string | null,
        isConnected?: boolean | null,
        disconnectedTime?: string | null,
        hasDisconnectWarning?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteClipHgDeviceMutationVariables = {
  input: DeleteClipHgDeviceInput,
  condition?: ModelClipHgDeviceConditionInput | null,
};

export type DeleteClipHgDeviceMutation = {
  deleteClipHgDevice?:  {
    __typename: "ClipHgDevice",
    deviceId: string,
    restroomId: string,
    co2SensorUsage?: number | null,
    createdAt: string,
    updatedAt: string,
    restroom?:  {
      __typename: "ClipHgRestroom",
      restroomId: string,
      prefAreaId?: string | null,
      cityAreaId?: string | null,
      wardAreaId?: string | null,
      name: string,
      address: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      latestLocationTime?: string | null,
      isWinter: boolean,
      isPremium?: boolean | null,
      useMin: number,
      useMax: number,
      topPosition?: number | null,
      leftPosition?: number | null,
      isMove?: boolean | null,
      pref?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      city?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      ward?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelClipHgDeviceConnection",
        items:  Array< {
          __typename: "ClipHgDevice",
          deviceId: string,
          restroomId: string,
          co2SensorUsage?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    toilets?:  {
      __typename: "ModelClipHgToiletConnection",
      items:  Array< {
        __typename: "ClipHgToilet",
        deviceId: string,
        name: string,
        usage: number,
        number: number,
        type: number,
        fixedFlowRate?: number | null,
        doorLockId?: string | null,
        isOccupied?: boolean | null,
        emergencyButtonId?: string | null,
        isConnected?: boolean | null,
        disconnectedTime?: string | null,
        hasDisconnectWarning?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateClipHgToiletMutationVariables = {
  input: CreateClipHgToiletInput,
  condition?: ModelClipHgToiletConditionInput | null,
};

export type CreateClipHgToiletMutation = {
  createClipHgToilet?:  {
    __typename: "ClipHgToilet",
    deviceId: string,
    name: string,
    usage: number,
    number: number,
    type: number,
    fixedFlowRate?: number | null,
    doorLockId?: string | null,
    isOccupied?: boolean | null,
    emergencyButtonId?: string | null,
    isConnected?: boolean | null,
    disconnectedTime?: string | null,
    hasDisconnectWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClipHgToiletMutationVariables = {
  input: DeleteClipHgToiletInput,
  condition?: ModelClipHgToiletConditionInput | null,
};

export type DeleteClipHgToiletMutation = {
  deleteClipHgToilet?:  {
    __typename: "ClipHgToilet",
    deviceId: string,
    name: string,
    usage: number,
    number: number,
    type: number,
    fixedFlowRate?: number | null,
    doorLockId?: string | null,
    isOccupied?: boolean | null,
    emergencyButtonId?: string | null,
    isConnected?: boolean | null,
    disconnectedTime?: string | null,
    hasDisconnectWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClipHgCalcDataMutationVariables = {
  input: DeleteClipHgCalcDataInput,
  condition?: ModelClipHgCalcDataConditionInput | null,
};

export type DeleteClipHgCalcDataMutation = {
  deleteClipHgCalcData?:  {
    __typename: "ClipHgCalcData",
    calcDataId: string,
    restroomId: string,
    deviceId: string,
    sendDateTime: string,
    carbonDioxide?: number | null,
    temperature?: number | null,
    humidity?: number | null,
    usage?: number | null,
    number?: number | null,
    type: number,
    flushCount?: number | null,
    flowTime?: number | null,
    flowRate?: number | null,
    ev?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClipHgUseCountMutationVariables = {
  input: DeleteClipHgUseCountInput,
  condition?: ModelClipHgUseCountConditionInput | null,
};

export type DeleteClipHgUseCountMutation = {
  deleteClipHgUseCount?:  {
    __typename: "ClipHgUseCount",
    useCountId: string,
    restroomId: string,
    deviceId: string,
    useDateTime: string,
    usage: number,
    type: number,
    number: number,
    washDeadline?: string | null,
    washDeviceId?: string | null,
    washNumber?: number | null,
    washTime?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClipHgSubtotalMutationVariables = {
  input: DeleteClipHgSubtotalInput,
  condition?: ModelClipHgSubtotalConditionInput | null,
};

export type DeleteClipHgSubtotalMutation = {
  deleteClipHgSubtotal?:  {
    __typename: "ClipHgSubtotal",
    restroomId: string,
    period: string,
    maleBothUse: number,
    maleToiletUse: number,
    maleWashUse: number,
    femaleBothUse: number,
    femaleToiletUse: number,
    femaleWashUse: number,
    multiBothUse: number,
    multiToiletUse: number,
    multiWashUse: number,
    maleCarbonDioxide?: number | null,
    femaleCarbonDioxide?: number | null,
    multiCarbonDioxide?: number | null,
    latestTemperature?: number | null,
    latestHumidity?: number | null,
    maleFlowRate?: number | null,
    femaleFlowRate?: number | null,
    multiFlowRate?: number | null,
    average?: number | null,
    elapsedDays?: number | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClipHgAreaMutationVariables = {
  input: CreateClipHgAreaInput,
  condition?: ModelClipHgAreaConditionInput | null,
};

export type CreateClipHgAreaMutation = {
  createClipHgArea?:  {
    __typename: "ClipHgArea",
    areaId: string,
    name: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClipHgAreaMutationVariables = {
  input: UpdateClipHgAreaInput,
  condition?: ModelClipHgAreaConditionInput | null,
};

export type UpdateClipHgAreaMutation = {
  updateClipHgArea?:  {
    __typename: "ClipHgArea",
    areaId: string,
    name: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClipHgAreaMutationVariables = {
  input: DeleteClipHgAreaInput,
  condition?: ModelClipHgAreaConditionInput | null,
};

export type DeleteClipHgAreaMutation = {
  deleteClipHgArea?:  {
    __typename: "ClipHgArea",
    areaId: string,
    name: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClipHgUserRestroomMutationVariables = {
  input: CreateClipHgUserRestroomInput,
  condition?: ModelClipHgUserRestroomConditionInput | null,
};

export type CreateClipHgUserRestroomMutation = {
  createClipHgUserRestroom?:  {
    __typename: "ClipHgUserRestroom",
    user: string,
    restroomId: string,
    isNotify?: boolean | null,
    isHiddenSecurityWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
    restrooms?:  {
      __typename: "ModelClipHgRestroomConnection",
      items:  Array< {
        __typename: "ClipHgRestroom",
        restroomId: string,
        prefAreaId?: string | null,
        cityAreaId?: string | null,
        wardAreaId?: string | null,
        name: string,
        address: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        latestLocationTime?: string | null,
        isWinter: boolean,
        isPremium?: boolean | null,
        useMin: number,
        useMax: number,
        topPosition?: number | null,
        leftPosition?: number | null,
        isMove?: boolean | null,
        pref?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        city?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        ward?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        devices?:  {
          __typename: "ModelClipHgDeviceConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateClipHgUserRestroomMutationVariables = {
  input: UpdateClipHgUserRestroomInput,
  condition?: ModelClipHgUserRestroomConditionInput | null,
};

export type UpdateClipHgUserRestroomMutation = {
  updateClipHgUserRestroom?:  {
    __typename: "ClipHgUserRestroom",
    user: string,
    restroomId: string,
    isNotify?: boolean | null,
    isHiddenSecurityWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
    restrooms?:  {
      __typename: "ModelClipHgRestroomConnection",
      items:  Array< {
        __typename: "ClipHgRestroom",
        restroomId: string,
        prefAreaId?: string | null,
        cityAreaId?: string | null,
        wardAreaId?: string | null,
        name: string,
        address: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        latestLocationTime?: string | null,
        isWinter: boolean,
        isPremium?: boolean | null,
        useMin: number,
        useMax: number,
        topPosition?: number | null,
        leftPosition?: number | null,
        isMove?: boolean | null,
        pref?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        city?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        ward?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        devices?:  {
          __typename: "ModelClipHgDeviceConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteClipHgUserRestroomMutationVariables = {
  input: DeleteClipHgUserRestroomInput,
  condition?: ModelClipHgUserRestroomConditionInput | null,
};

export type DeleteClipHgUserRestroomMutation = {
  deleteClipHgUserRestroom?:  {
    __typename: "ClipHgUserRestroom",
    user: string,
    restroomId: string,
    isNotify?: boolean | null,
    isHiddenSecurityWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
    restrooms?:  {
      __typename: "ModelClipHgRestroomConnection",
      items:  Array< {
        __typename: "ClipHgRestroom",
        restroomId: string,
        prefAreaId?: string | null,
        cityAreaId?: string | null,
        wardAreaId?: string | null,
        name: string,
        address: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        latestLocationTime?: string | null,
        isWinter: boolean,
        isPremium?: boolean | null,
        useMin: number,
        useMax: number,
        topPosition?: number | null,
        leftPosition?: number | null,
        isMove?: boolean | null,
        pref?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        city?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        ward?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        devices?:  {
          __typename: "ModelClipHgDeviceConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateClipHgRestroomMutationVariables = {
  input: UpdateClipHgRestroomInput,
  condition?: ModelClipHgRestroomConditionInput | null,
};

export type UpdateClipHgRestroomMutation = {
  updateClipHgRestroom?:  {
    __typename: "ClipHgRestroom",
    restroomId: string,
    prefAreaId?: string | null,
    cityAreaId?: string | null,
    wardAreaId?: string | null,
    name: string,
    address: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    latestLocationTime?: string | null,
    isWinter: boolean,
    isPremium?: boolean | null,
    useMin: number,
    useMax: number,
    topPosition?: number | null,
    leftPosition?: number | null,
    isMove?: boolean | null,
    pref?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    city?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    ward?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelClipHgDeviceConnection",
      items:  Array< {
        __typename: "ClipHgDevice",
        deviceId: string,
        restroomId: string,
        co2SensorUsage?: number | null,
        createdAt: string,
        updatedAt: string,
        restroom?:  {
          __typename: "ClipHgRestroom",
          restroomId: string,
          prefAreaId?: string | null,
          cityAreaId?: string | null,
          wardAreaId?: string | null,
          name: string,
          address: string,
          latestLocationTime?: string | null,
          isWinter: boolean,
          isPremium?: boolean | null,
          useMin: number,
          useMax: number,
          topPosition?: number | null,
          leftPosition?: number | null,
          isMove?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        toilets?:  {
          __typename: "ModelClipHgToiletConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateClipHgToiletMutationVariables = {
  input: UpdateClipHgToiletInput,
  condition?: ModelClipHgToiletConditionInput | null,
};

export type UpdateClipHgToiletMutation = {
  updateClipHgToilet?:  {
    __typename: "ClipHgToilet",
    deviceId: string,
    name: string,
    usage: number,
    number: number,
    type: number,
    fixedFlowRate?: number | null,
    doorLockId?: string | null,
    isOccupied?: boolean | null,
    emergencyButtonId?: string | null,
    isConnected?: boolean | null,
    disconnectedTime?: string | null,
    hasDisconnectWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClipHgCalcDataMutationVariables = {
  input: CreateClipHgCalcDataInput,
  condition?: ModelClipHgCalcDataConditionInput | null,
};

export type CreateClipHgCalcDataMutation = {
  createClipHgCalcData?:  {
    __typename: "ClipHgCalcData",
    calcDataId: string,
    restroomId: string,
    deviceId: string,
    sendDateTime: string,
    carbonDioxide?: number | null,
    temperature?: number | null,
    humidity?: number | null,
    usage?: number | null,
    number?: number | null,
    type: number,
    flushCount?: number | null,
    flowTime?: number | null,
    flowRate?: number | null,
    ev?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClipHgCalcDataMutationVariables = {
  input: UpdateClipHgCalcDataInput,
  condition?: ModelClipHgCalcDataConditionInput | null,
};

export type UpdateClipHgCalcDataMutation = {
  updateClipHgCalcData?:  {
    __typename: "ClipHgCalcData",
    calcDataId: string,
    restroomId: string,
    deviceId: string,
    sendDateTime: string,
    carbonDioxide?: number | null,
    temperature?: number | null,
    humidity?: number | null,
    usage?: number | null,
    number?: number | null,
    type: number,
    flushCount?: number | null,
    flowTime?: number | null,
    flowRate?: number | null,
    ev?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClipHgWarningMutationVariables = {
  input: CreateClipHgWarningInput,
  condition?: ModelClipHgWarningConditionInput | null,
};

export type CreateClipHgWarningMutation = {
  createClipHgWarning?:  {
    __typename: "ClipHgWarning",
    warningId: string,
    restroomId: string,
    deviceId?: string | null,
    sendDateTime: string,
    warningNumber: number,
    warningType?: number | null,
    usage?: number | null,
    number?: number | null,
    type?: number | null,
    toiletName?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClipHgWarningMutationVariables = {
  input: UpdateClipHgWarningInput,
  condition?: ModelClipHgWarningConditionInput | null,
};

export type UpdateClipHgWarningMutation = {
  updateClipHgWarning?:  {
    __typename: "ClipHgWarning",
    warningId: string,
    restroomId: string,
    deviceId?: string | null,
    sendDateTime: string,
    warningNumber: number,
    warningType?: number | null,
    usage?: number | null,
    number?: number | null,
    type?: number | null,
    toiletName?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteClipHgWarningMutationVariables = {
  input: DeleteClipHgWarningInput,
  condition?: ModelClipHgWarningConditionInput | null,
};

export type DeleteClipHgWarningMutation = {
  deleteClipHgWarning?:  {
    __typename: "ClipHgWarning",
    warningId: string,
    restroomId: string,
    deviceId?: string | null,
    sendDateTime: string,
    warningNumber: number,
    warningType?: number | null,
    usage?: number | null,
    number?: number | null,
    type?: number | null,
    toiletName?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClipHgUseCountMutationVariables = {
  input: CreateClipHgUseCountInput,
  condition?: ModelClipHgUseCountConditionInput | null,
};

export type CreateClipHgUseCountMutation = {
  createClipHgUseCount?:  {
    __typename: "ClipHgUseCount",
    useCountId: string,
    restroomId: string,
    deviceId: string,
    useDateTime: string,
    usage: number,
    type: number,
    number: number,
    washDeadline?: string | null,
    washDeviceId?: string | null,
    washNumber?: number | null,
    washTime?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClipHgUseCountMutationVariables = {
  input: UpdateClipHgUseCountInput,
  condition?: ModelClipHgUseCountConditionInput | null,
};

export type UpdateClipHgUseCountMutation = {
  updateClipHgUseCount?:  {
    __typename: "ClipHgUseCount",
    useCountId: string,
    restroomId: string,
    deviceId: string,
    useDateTime: string,
    usage: number,
    type: number,
    number: number,
    washDeadline?: string | null,
    washDeviceId?: string | null,
    washNumber?: number | null,
    washTime?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateClipHgSubtotalMutationVariables = {
  input: CreateClipHgSubtotalInput,
  condition?: ModelClipHgSubtotalConditionInput | null,
};

export type CreateClipHgSubtotalMutation = {
  createClipHgSubtotal?:  {
    __typename: "ClipHgSubtotal",
    restroomId: string,
    period: string,
    maleBothUse: number,
    maleToiletUse: number,
    maleWashUse: number,
    femaleBothUse: number,
    femaleToiletUse: number,
    femaleWashUse: number,
    multiBothUse: number,
    multiToiletUse: number,
    multiWashUse: number,
    maleCarbonDioxide?: number | null,
    femaleCarbonDioxide?: number | null,
    multiCarbonDioxide?: number | null,
    latestTemperature?: number | null,
    latestHumidity?: number | null,
    maleFlowRate?: number | null,
    femaleFlowRate?: number | null,
    multiFlowRate?: number | null,
    average?: number | null,
    elapsedDays?: number | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateClipHgSubtotalMutationVariables = {
  input: UpdateClipHgSubtotalInput,
  condition?: ModelClipHgSubtotalConditionInput | null,
};

export type UpdateClipHgSubtotalMutation = {
  updateClipHgSubtotal?:  {
    __typename: "ClipHgSubtotal",
    restroomId: string,
    period: string,
    maleBothUse: number,
    maleToiletUse: number,
    maleWashUse: number,
    femaleBothUse: number,
    femaleToiletUse: number,
    femaleWashUse: number,
    multiBothUse: number,
    multiToiletUse: number,
    multiWashUse: number,
    maleCarbonDioxide?: number | null,
    femaleCarbonDioxide?: number | null,
    multiCarbonDioxide?: number | null,
    latestTemperature?: number | null,
    latestHumidity?: number | null,
    maleFlowRate?: number | null,
    femaleFlowRate?: number | null,
    multiFlowRate?: number | null,
    average?: number | null,
    elapsedDays?: number | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetClipHgAreaQueryVariables = {
  areaId: string,
};

export type GetClipHgAreaQuery = {
  getClipHgArea?:  {
    __typename: "ClipHgArea",
    areaId: string,
    name: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClipHgAreasQueryVariables = {
  areaId?: string | null,
  filter?: ModelClipHgAreaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClipHgAreasQuery = {
  listClipHgAreas?:  {
    __typename: "ModelClipHgAreaConnection",
    items:  Array< {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClipHgRestroomQueryVariables = {
  restroomId: string,
};

export type GetClipHgRestroomQuery = {
  getClipHgRestroom?:  {
    __typename: "ClipHgRestroom",
    restroomId: string,
    prefAreaId?: string | null,
    cityAreaId?: string | null,
    wardAreaId?: string | null,
    name: string,
    address: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    latestLocationTime?: string | null,
    isWinter: boolean,
    isPremium?: boolean | null,
    useMin: number,
    useMax: number,
    topPosition?: number | null,
    leftPosition?: number | null,
    isMove?: boolean | null,
    pref?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    city?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    ward?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelClipHgDeviceConnection",
      items:  Array< {
        __typename: "ClipHgDevice",
        deviceId: string,
        restroomId: string,
        co2SensorUsage?: number | null,
        createdAt: string,
        updatedAt: string,
        restroom?:  {
          __typename: "ClipHgRestroom",
          restroomId: string,
          prefAreaId?: string | null,
          cityAreaId?: string | null,
          wardAreaId?: string | null,
          name: string,
          address: string,
          latestLocationTime?: string | null,
          isWinter: boolean,
          isPremium?: boolean | null,
          useMin: number,
          useMax: number,
          topPosition?: number | null,
          leftPosition?: number | null,
          isMove?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        toilets?:  {
          __typename: "ModelClipHgToiletConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListClipHgRestroomsQueryVariables = {
  restroomId?: string | null,
  filter?: ModelClipHgRestroomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClipHgRestroomsQuery = {
  listClipHgRestrooms?:  {
    __typename: "ModelClipHgRestroomConnection",
    items:  Array< {
      __typename: "ClipHgRestroom",
      restroomId: string,
      prefAreaId?: string | null,
      cityAreaId?: string | null,
      wardAreaId?: string | null,
      name: string,
      address: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      latestLocationTime?: string | null,
      isWinter: boolean,
      isPremium?: boolean | null,
      useMin: number,
      useMax: number,
      topPosition?: number | null,
      leftPosition?: number | null,
      isMove?: boolean | null,
      pref?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      city?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      ward?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelClipHgDeviceConnection",
        items:  Array< {
          __typename: "ClipHgDevice",
          deviceId: string,
          restroomId: string,
          co2SensorUsage?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClipHgDeviceQueryVariables = {
  deviceId: string,
};

export type GetClipHgDeviceQuery = {
  getClipHgDevice?:  {
    __typename: "ClipHgDevice",
    deviceId: string,
    restroomId: string,
    co2SensorUsage?: number | null,
    createdAt: string,
    updatedAt: string,
    restroom?:  {
      __typename: "ClipHgRestroom",
      restroomId: string,
      prefAreaId?: string | null,
      cityAreaId?: string | null,
      wardAreaId?: string | null,
      name: string,
      address: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      latestLocationTime?: string | null,
      isWinter: boolean,
      isPremium?: boolean | null,
      useMin: number,
      useMax: number,
      topPosition?: number | null,
      leftPosition?: number | null,
      isMove?: boolean | null,
      pref?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      city?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      ward?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelClipHgDeviceConnection",
        items:  Array< {
          __typename: "ClipHgDevice",
          deviceId: string,
          restroomId: string,
          co2SensorUsage?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    toilets?:  {
      __typename: "ModelClipHgToiletConnection",
      items:  Array< {
        __typename: "ClipHgToilet",
        deviceId: string,
        name: string,
        usage: number,
        number: number,
        type: number,
        fixedFlowRate?: number | null,
        doorLockId?: string | null,
        isOccupied?: boolean | null,
        emergencyButtonId?: string | null,
        isConnected?: boolean | null,
        disconnectedTime?: string | null,
        hasDisconnectWarning?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListClipHgDevicesQueryVariables = {
  deviceId?: string | null,
  filter?: ModelClipHgDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClipHgDevicesQuery = {
  listClipHgDevices?:  {
    __typename: "ModelClipHgDeviceConnection",
    items:  Array< {
      __typename: "ClipHgDevice",
      deviceId: string,
      restroomId: string,
      co2SensorUsage?: number | null,
      createdAt: string,
      updatedAt: string,
      restroom?:  {
        __typename: "ClipHgRestroom",
        restroomId: string,
        prefAreaId?: string | null,
        cityAreaId?: string | null,
        wardAreaId?: string | null,
        name: string,
        address: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        latestLocationTime?: string | null,
        isWinter: boolean,
        isPremium?: boolean | null,
        useMin: number,
        useMax: number,
        topPosition?: number | null,
        leftPosition?: number | null,
        isMove?: boolean | null,
        pref?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        city?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        ward?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        devices?:  {
          __typename: "ModelClipHgDeviceConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      toilets?:  {
        __typename: "ModelClipHgToiletConnection",
        items:  Array< {
          __typename: "ClipHgToilet",
          deviceId: string,
          name: string,
          usage: number,
          number: number,
          type: number,
          fixedFlowRate?: number | null,
          doorLockId?: string | null,
          isOccupied?: boolean | null,
          emergencyButtonId?: string | null,
          isConnected?: boolean | null,
          disconnectedTime?: string | null,
          hasDisconnectWarning?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListDevicesbyRestroomIdQueryVariables = {
  restroomId?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgDeviceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDevicesbyRestroomIdQuery = {
  listDevicesbyRestroomId?:  {
    __typename: "ModelClipHgDeviceConnection",
    items:  Array< {
      __typename: "ClipHgDevice",
      deviceId: string,
      restroomId: string,
      co2SensorUsage?: number | null,
      createdAt: string,
      updatedAt: string,
      restroom?:  {
        __typename: "ClipHgRestroom",
        restroomId: string,
        prefAreaId?: string | null,
        cityAreaId?: string | null,
        wardAreaId?: string | null,
        name: string,
        address: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        latestLocationTime?: string | null,
        isWinter: boolean,
        isPremium?: boolean | null,
        useMin: number,
        useMax: number,
        topPosition?: number | null,
        leftPosition?: number | null,
        isMove?: boolean | null,
        pref?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        city?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        ward?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        devices?:  {
          __typename: "ModelClipHgDeviceConnection",
          nextToken?: string | null,
        } | null,
      } | null,
      toilets?:  {
        __typename: "ModelClipHgToiletConnection",
        items:  Array< {
          __typename: "ClipHgToilet",
          deviceId: string,
          name: string,
          usage: number,
          number: number,
          type: number,
          fixedFlowRate?: number | null,
          doorLockId?: string | null,
          isOccupied?: boolean | null,
          emergencyButtonId?: string | null,
          isConnected?: boolean | null,
          disconnectedTime?: string | null,
          hasDisconnectWarning?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClipHgToiletQueryVariables = {
  deviceId: string,
  number: number,
};

export type GetClipHgToiletQuery = {
  getClipHgToilet?:  {
    __typename: "ClipHgToilet",
    deviceId: string,
    name: string,
    usage: number,
    number: number,
    type: number,
    fixedFlowRate?: number | null,
    doorLockId?: string | null,
    isOccupied?: boolean | null,
    emergencyButtonId?: string | null,
    isConnected?: boolean | null,
    disconnectedTime?: string | null,
    hasDisconnectWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClipHgToiletsQueryVariables = {
  deviceId?: string | null,
  number?: ModelIntKeyConditionInput | null,
  filter?: ModelClipHgToiletFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClipHgToiletsQuery = {
  listClipHgToilets?:  {
    __typename: "ModelClipHgToiletConnection",
    items:  Array< {
      __typename: "ClipHgToilet",
      deviceId: string,
      name: string,
      usage: number,
      number: number,
      type: number,
      fixedFlowRate?: number | null,
      doorLockId?: string | null,
      isOccupied?: boolean | null,
      emergencyButtonId?: string | null,
      isConnected?: boolean | null,
      disconnectedTime?: string | null,
      hasDisconnectWarning?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClipHgCalcDataQueryVariables = {
  calcDataId: string,
};

export type GetClipHgCalcDataQuery = {
  getClipHgCalcData?:  {
    __typename: "ClipHgCalcData",
    calcDataId: string,
    restroomId: string,
    deviceId: string,
    sendDateTime: string,
    carbonDioxide?: number | null,
    temperature?: number | null,
    humidity?: number | null,
    usage?: number | null,
    number?: number | null,
    type: number,
    flushCount?: number | null,
    flowTime?: number | null,
    flowRate?: number | null,
    ev?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClipHgCalcDatasQueryVariables = {
  calcDataId?: string | null,
  filter?: ModelClipHgCalcDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClipHgCalcDatasQuery = {
  listClipHgCalcDatas?:  {
    __typename: "ModelClipHgCalcDataConnection",
    items:  Array< {
      __typename: "ClipHgCalcData",
      calcDataId: string,
      restroomId: string,
      deviceId: string,
      sendDateTime: string,
      carbonDioxide?: number | null,
      temperature?: number | null,
      humidity?: number | null,
      usage?: number | null,
      number?: number | null,
      type: number,
      flushCount?: number | null,
      flowTime?: number | null,
      flowRate?: number | null,
      ev?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCalcDatasByDeviceIdQueryVariables = {
  deviceId?: string | null,
  sendDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgCalcDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCalcDatasByDeviceIdQuery = {
  listCalcDatasByDeviceId?:  {
    __typename: "ModelClipHgCalcDataConnection",
    items:  Array< {
      __typename: "ClipHgCalcData",
      calcDataId: string,
      restroomId: string,
      deviceId: string,
      sendDateTime: string,
      carbonDioxide?: number | null,
      temperature?: number | null,
      humidity?: number | null,
      usage?: number | null,
      number?: number | null,
      type: number,
      flushCount?: number | null,
      flowTime?: number | null,
      flowRate?: number | null,
      ev?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCalcDatasSortByCreatedAtQueryVariables = {
  deviceId?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgCalcDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCalcDatasSortByCreatedAtQuery = {
  listCalcDatasSortByCreatedAt?:  {
    __typename: "ModelClipHgCalcDataConnection",
    items:  Array< {
      __typename: "ClipHgCalcData",
      calcDataId: string,
      restroomId: string,
      deviceId: string,
      sendDateTime: string,
      carbonDioxide?: number | null,
      temperature?: number | null,
      humidity?: number | null,
      usage?: number | null,
      number?: number | null,
      type: number,
      flushCount?: number | null,
      flowTime?: number | null,
      flowRate?: number | null,
      ev?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCalcDatasSortByTypeQueryVariables = {
  restroomId?: string | null,
  typeSendDateTime?: ModelClipHgCalcDataSortByTypeCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgCalcDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCalcDatasSortByTypeQuery = {
  listCalcDatasSortByType?:  {
    __typename: "ModelClipHgCalcDataConnection",
    items:  Array< {
      __typename: "ClipHgCalcData",
      calcDataId: string,
      restroomId: string,
      deviceId: string,
      sendDateTime: string,
      carbonDioxide?: number | null,
      temperature?: number | null,
      humidity?: number | null,
      usage?: number | null,
      number?: number | null,
      type: number,
      flushCount?: number | null,
      flowTime?: number | null,
      flowRate?: number | null,
      ev?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListCalcDatasByRestroomIdQueryVariables = {
  restroomId?: string | null,
  sendDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgCalcDataFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCalcDatasByRestroomIdQuery = {
  listCalcDatasByRestroomId?:  {
    __typename: "ModelClipHgCalcDataConnection",
    items:  Array< {
      __typename: "ClipHgCalcData",
      calcDataId: string,
      restroomId: string,
      deviceId: string,
      sendDateTime: string,
      carbonDioxide?: number | null,
      temperature?: number | null,
      humidity?: number | null,
      usage?: number | null,
      number?: number | null,
      type: number,
      flushCount?: number | null,
      flowTime?: number | null,
      flowRate?: number | null,
      ev?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClipHgWarningQueryVariables = {
  warningId: string,
};

export type GetClipHgWarningQuery = {
  getClipHgWarning?:  {
    __typename: "ClipHgWarning",
    warningId: string,
    restroomId: string,
    deviceId?: string | null,
    sendDateTime: string,
    warningNumber: number,
    warningType?: number | null,
    usage?: number | null,
    number?: number | null,
    type?: number | null,
    toiletName?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClipHgWarningsQueryVariables = {
  warningId?: string | null,
  filter?: ModelClipHgWarningFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClipHgWarningsQuery = {
  listClipHgWarnings?:  {
    __typename: "ModelClipHgWarningConnection",
    items:  Array< {
      __typename: "ClipHgWarning",
      warningId: string,
      restroomId: string,
      deviceId?: string | null,
      sendDateTime: string,
      warningNumber: number,
      warningType?: number | null,
      usage?: number | null,
      number?: number | null,
      type?: number | null,
      toiletName?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWarningsSortByTimeQueryVariables = {
  deviceId?: string | null,
  sendDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgWarningFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWarningsSortByTimeQuery = {
  listWarningsSortByTime?:  {
    __typename: "ModelClipHgWarningConnection",
    items:  Array< {
      __typename: "ClipHgWarning",
      warningId: string,
      restroomId: string,
      deviceId?: string | null,
      sendDateTime: string,
      warningNumber: number,
      warningType?: number | null,
      usage?: number | null,
      number?: number | null,
      type?: number | null,
      toiletName?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListWarningsByRestroomIdQueryVariables = {
  restroomId?: string | null,
  sendDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgWarningFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWarningsByRestroomIdQuery = {
  listWarningsByRestroomId?:  {
    __typename: "ModelClipHgWarningConnection",
    items:  Array< {
      __typename: "ClipHgWarning",
      warningId: string,
      restroomId: string,
      deviceId?: string | null,
      sendDateTime: string,
      warningNumber: number,
      warningType?: number | null,
      usage?: number | null,
      number?: number | null,
      type?: number | null,
      toiletName?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClipHgUseCountQueryVariables = {
  useCountId: string,
};

export type GetClipHgUseCountQuery = {
  getClipHgUseCount?:  {
    __typename: "ClipHgUseCount",
    useCountId: string,
    restroomId: string,
    deviceId: string,
    useDateTime: string,
    usage: number,
    type: number,
    number: number,
    washDeadline?: string | null,
    washDeviceId?: string | null,
    washNumber?: number | null,
    washTime?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClipHgUseCountsQueryVariables = {
  useCountId?: string | null,
  filter?: ModelClipHgUseCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClipHgUseCountsQuery = {
  listClipHgUseCounts?:  {
    __typename: "ModelClipHgUseCountConnection",
    items:  Array< {
      __typename: "ClipHgUseCount",
      useCountId: string,
      restroomId: string,
      deviceId: string,
      useDateTime: string,
      usage: number,
      type: number,
      number: number,
      washDeadline?: string | null,
      washDeviceId?: string | null,
      washNumber?: number | null,
      washTime?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUseCountsByRestroomIdQueryVariables = {
  restroomId?: string | null,
  useDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgUseCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUseCountsByRestroomIdQuery = {
  listUseCountsByRestroomId?:  {
    __typename: "ModelClipHgUseCountConnection",
    items:  Array< {
      __typename: "ClipHgUseCount",
      useCountId: string,
      restroomId: string,
      deviceId: string,
      useDateTime: string,
      usage: number,
      type: number,
      number: number,
      washDeadline?: string | null,
      washDeviceId?: string | null,
      washNumber?: number | null,
      washTime?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUseCountsByDeviceIdQueryVariables = {
  deviceId?: string | null,
  useDateTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgUseCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUseCountsByDeviceIdQuery = {
  listUseCountsByDeviceId?:  {
    __typename: "ModelClipHgUseCountConnection",
    items:  Array< {
      __typename: "ClipHgUseCount",
      useCountId: string,
      restroomId: string,
      deviceId: string,
      useDateTime: string,
      usage: number,
      type: number,
      number: number,
      washDeadline?: string | null,
      washDeviceId?: string | null,
      washNumber?: number | null,
      washTime?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUseCountsSortByDeadlineQueryVariables = {
  restroomId?: string | null,
  washDeadline?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgUseCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUseCountsSortByDeadlineQuery = {
  listUseCountsSortByDeadline?:  {
    __typename: "ModelClipHgUseCountConnection",
    items:  Array< {
      __typename: "ClipHgUseCount",
      useCountId: string,
      restroomId: string,
      deviceId: string,
      useDateTime: string,
      usage: number,
      type: number,
      number: number,
      washDeadline?: string | null,
      washDeviceId?: string | null,
      washNumber?: number | null,
      washTime?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ListUseCountsByWashDeviceIdQueryVariables = {
  washDeviceId?: string | null,
  washTime?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelClipHgUseCountFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUseCountsByWashDeviceIdQuery = {
  listUseCountsByWashDeviceId?:  {
    __typename: "ModelClipHgUseCountConnection",
    items:  Array< {
      __typename: "ClipHgUseCount",
      useCountId: string,
      restroomId: string,
      deviceId: string,
      useDateTime: string,
      usage: number,
      type: number,
      number: number,
      washDeadline?: string | null,
      washDeviceId?: string | null,
      washNumber?: number | null,
      washTime?: string | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClipHgSubtotalQueryVariables = {
  restroomId: string,
  period: string,
};

export type GetClipHgSubtotalQuery = {
  getClipHgSubtotal?:  {
    __typename: "ClipHgSubtotal",
    restroomId: string,
    period: string,
    maleBothUse: number,
    maleToiletUse: number,
    maleWashUse: number,
    femaleBothUse: number,
    femaleToiletUse: number,
    femaleWashUse: number,
    multiBothUse: number,
    multiToiletUse: number,
    multiWashUse: number,
    maleCarbonDioxide?: number | null,
    femaleCarbonDioxide?: number | null,
    multiCarbonDioxide?: number | null,
    latestTemperature?: number | null,
    latestHumidity?: number | null,
    maleFlowRate?: number | null,
    femaleFlowRate?: number | null,
    multiFlowRate?: number | null,
    average?: number | null,
    elapsedDays?: number | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListClipHgSubtotalsQueryVariables = {
  restroomId?: string | null,
  period?: ModelStringKeyConditionInput | null,
  filter?: ModelClipHgSubtotalFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClipHgSubtotalsQuery = {
  listClipHgSubtotals?:  {
    __typename: "ModelClipHgSubtotalConnection",
    items:  Array< {
      __typename: "ClipHgSubtotal",
      restroomId: string,
      period: string,
      maleBothUse: number,
      maleToiletUse: number,
      maleWashUse: number,
      femaleBothUse: number,
      femaleToiletUse: number,
      femaleWashUse: number,
      multiBothUse: number,
      multiToiletUse: number,
      multiWashUse: number,
      maleCarbonDioxide?: number | null,
      femaleCarbonDioxide?: number | null,
      multiCarbonDioxide?: number | null,
      latestTemperature?: number | null,
      latestHumidity?: number | null,
      maleFlowRate?: number | null,
      femaleFlowRate?: number | null,
      multiFlowRate?: number | null,
      average?: number | null,
      elapsedDays?: number | null,
      ttl?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetClipHgUserRestroomQueryVariables = {
  user: string,
  restroomId: string,
};

export type GetClipHgUserRestroomQuery = {
  getClipHgUserRestroom?:  {
    __typename: "ClipHgUserRestroom",
    user: string,
    restroomId: string,
    isNotify?: boolean | null,
    isHiddenSecurityWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
    restrooms?:  {
      __typename: "ModelClipHgRestroomConnection",
      items:  Array< {
        __typename: "ClipHgRestroom",
        restroomId: string,
        prefAreaId?: string | null,
        cityAreaId?: string | null,
        wardAreaId?: string | null,
        name: string,
        address: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        latestLocationTime?: string | null,
        isWinter: boolean,
        isPremium?: boolean | null,
        useMin: number,
        useMax: number,
        topPosition?: number | null,
        leftPosition?: number | null,
        isMove?: boolean | null,
        pref?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        city?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        ward?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        devices?:  {
          __typename: "ModelClipHgDeviceConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListClipHgUserRestroomsQueryVariables = {
  user?: string | null,
  restroomId?: ModelIDKeyConditionInput | null,
  filter?: ModelClipHgUserRestroomFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListClipHgUserRestroomsQuery = {
  listClipHgUserRestrooms?:  {
    __typename: "ModelClipHgUserRestroomConnection",
    items:  Array< {
      __typename: "ClipHgUserRestroom",
      user: string,
      restroomId: string,
      isNotify?: boolean | null,
      isHiddenSecurityWarning?: boolean | null,
      createdAt: string,
      updatedAt: string,
      restrooms?:  {
        __typename: "ModelClipHgRestroomConnection",
        items:  Array< {
          __typename: "ClipHgRestroom",
          restroomId: string,
          prefAreaId?: string | null,
          cityAreaId?: string | null,
          wardAreaId?: string | null,
          name: string,
          address: string,
          latestLocationTime?: string | null,
          isWinter: boolean,
          isPremium?: boolean | null,
          useMin: number,
          useMax: number,
          topPosition?: number | null,
          leftPosition?: number | null,
          isMove?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateClipHgAreaSubscription = {
  onCreateClipHgArea?:  {
    __typename: "ClipHgArea",
    areaId: string,
    name: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClipHgAreaSubscription = {
  onUpdateClipHgArea?:  {
    __typename: "ClipHgArea",
    areaId: string,
    name: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClipHgAreaSubscription = {
  onDeleteClipHgArea?:  {
    __typename: "ClipHgArea",
    areaId: string,
    name: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClipHgRestroomSubscription = {
  onCreateClipHgRestroom?:  {
    __typename: "ClipHgRestroom",
    restroomId: string,
    prefAreaId?: string | null,
    cityAreaId?: string | null,
    wardAreaId?: string | null,
    name: string,
    address: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    latestLocationTime?: string | null,
    isWinter: boolean,
    isPremium?: boolean | null,
    useMin: number,
    useMax: number,
    topPosition?: number | null,
    leftPosition?: number | null,
    isMove?: boolean | null,
    pref?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    city?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    ward?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelClipHgDeviceConnection",
      items:  Array< {
        __typename: "ClipHgDevice",
        deviceId: string,
        restroomId: string,
        co2SensorUsage?: number | null,
        createdAt: string,
        updatedAt: string,
        restroom?:  {
          __typename: "ClipHgRestroom",
          restroomId: string,
          prefAreaId?: string | null,
          cityAreaId?: string | null,
          wardAreaId?: string | null,
          name: string,
          address: string,
          latestLocationTime?: string | null,
          isWinter: boolean,
          isPremium?: boolean | null,
          useMin: number,
          useMax: number,
          topPosition?: number | null,
          leftPosition?: number | null,
          isMove?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        toilets?:  {
          __typename: "ModelClipHgToiletConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateClipHgRestroomSubscription = {
  onUpdateClipHgRestroom?:  {
    __typename: "ClipHgRestroom",
    restroomId: string,
    prefAreaId?: string | null,
    cityAreaId?: string | null,
    wardAreaId?: string | null,
    name: string,
    address: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    latestLocationTime?: string | null,
    isWinter: boolean,
    isPremium?: boolean | null,
    useMin: number,
    useMax: number,
    topPosition?: number | null,
    leftPosition?: number | null,
    isMove?: boolean | null,
    pref?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    city?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    ward?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelClipHgDeviceConnection",
      items:  Array< {
        __typename: "ClipHgDevice",
        deviceId: string,
        restroomId: string,
        co2SensorUsage?: number | null,
        createdAt: string,
        updatedAt: string,
        restroom?:  {
          __typename: "ClipHgRestroom",
          restroomId: string,
          prefAreaId?: string | null,
          cityAreaId?: string | null,
          wardAreaId?: string | null,
          name: string,
          address: string,
          latestLocationTime?: string | null,
          isWinter: boolean,
          isPremium?: boolean | null,
          useMin: number,
          useMax: number,
          topPosition?: number | null,
          leftPosition?: number | null,
          isMove?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        toilets?:  {
          __typename: "ModelClipHgToiletConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteClipHgRestroomSubscription = {
  onDeleteClipHgRestroom?:  {
    __typename: "ClipHgRestroom",
    restroomId: string,
    prefAreaId?: string | null,
    cityAreaId?: string | null,
    wardAreaId?: string | null,
    name: string,
    address: string,
    location:  {
      __typename: "Location",
      lat: number,
      lon: number,
    },
    latestLocationTime?: string | null,
    isWinter: boolean,
    isPremium?: boolean | null,
    useMin: number,
    useMax: number,
    topPosition?: number | null,
    leftPosition?: number | null,
    isMove?: boolean | null,
    pref?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    city?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    ward?:  {
      __typename: "ClipHgArea",
      areaId: string,
      name: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    devices?:  {
      __typename: "ModelClipHgDeviceConnection",
      items:  Array< {
        __typename: "ClipHgDevice",
        deviceId: string,
        restroomId: string,
        co2SensorUsage?: number | null,
        createdAt: string,
        updatedAt: string,
        restroom?:  {
          __typename: "ClipHgRestroom",
          restroomId: string,
          prefAreaId?: string | null,
          cityAreaId?: string | null,
          wardAreaId?: string | null,
          name: string,
          address: string,
          latestLocationTime?: string | null,
          isWinter: boolean,
          isPremium?: boolean | null,
          useMin: number,
          useMax: number,
          topPosition?: number | null,
          leftPosition?: number | null,
          isMove?: boolean | null,
          createdAt: string,
          updatedAt: string,
        } | null,
        toilets?:  {
          __typename: "ModelClipHgToiletConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateClipHgDeviceSubscription = {
  onCreateClipHgDevice?:  {
    __typename: "ClipHgDevice",
    deviceId: string,
    restroomId: string,
    co2SensorUsage?: number | null,
    createdAt: string,
    updatedAt: string,
    restroom?:  {
      __typename: "ClipHgRestroom",
      restroomId: string,
      prefAreaId?: string | null,
      cityAreaId?: string | null,
      wardAreaId?: string | null,
      name: string,
      address: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      latestLocationTime?: string | null,
      isWinter: boolean,
      isPremium?: boolean | null,
      useMin: number,
      useMax: number,
      topPosition?: number | null,
      leftPosition?: number | null,
      isMove?: boolean | null,
      pref?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      city?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      ward?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelClipHgDeviceConnection",
        items:  Array< {
          __typename: "ClipHgDevice",
          deviceId: string,
          restroomId: string,
          co2SensorUsage?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    toilets?:  {
      __typename: "ModelClipHgToiletConnection",
      items:  Array< {
        __typename: "ClipHgToilet",
        deviceId: string,
        name: string,
        usage: number,
        number: number,
        type: number,
        fixedFlowRate?: number | null,
        doorLockId?: string | null,
        isOccupied?: boolean | null,
        emergencyButtonId?: string | null,
        isConnected?: boolean | null,
        disconnectedTime?: string | null,
        hasDisconnectWarning?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateClipHgDeviceSubscription = {
  onUpdateClipHgDevice?:  {
    __typename: "ClipHgDevice",
    deviceId: string,
    restroomId: string,
    co2SensorUsage?: number | null,
    createdAt: string,
    updatedAt: string,
    restroom?:  {
      __typename: "ClipHgRestroom",
      restroomId: string,
      prefAreaId?: string | null,
      cityAreaId?: string | null,
      wardAreaId?: string | null,
      name: string,
      address: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      latestLocationTime?: string | null,
      isWinter: boolean,
      isPremium?: boolean | null,
      useMin: number,
      useMax: number,
      topPosition?: number | null,
      leftPosition?: number | null,
      isMove?: boolean | null,
      pref?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      city?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      ward?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelClipHgDeviceConnection",
        items:  Array< {
          __typename: "ClipHgDevice",
          deviceId: string,
          restroomId: string,
          co2SensorUsage?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    toilets?:  {
      __typename: "ModelClipHgToiletConnection",
      items:  Array< {
        __typename: "ClipHgToilet",
        deviceId: string,
        name: string,
        usage: number,
        number: number,
        type: number,
        fixedFlowRate?: number | null,
        doorLockId?: string | null,
        isOccupied?: boolean | null,
        emergencyButtonId?: string | null,
        isConnected?: boolean | null,
        disconnectedTime?: string | null,
        hasDisconnectWarning?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteClipHgDeviceSubscription = {
  onDeleteClipHgDevice?:  {
    __typename: "ClipHgDevice",
    deviceId: string,
    restroomId: string,
    co2SensorUsage?: number | null,
    createdAt: string,
    updatedAt: string,
    restroom?:  {
      __typename: "ClipHgRestroom",
      restroomId: string,
      prefAreaId?: string | null,
      cityAreaId?: string | null,
      wardAreaId?: string | null,
      name: string,
      address: string,
      location:  {
        __typename: "Location",
        lat: number,
        lon: number,
      },
      latestLocationTime?: string | null,
      isWinter: boolean,
      isPremium?: boolean | null,
      useMin: number,
      useMax: number,
      topPosition?: number | null,
      leftPosition?: number | null,
      isMove?: boolean | null,
      pref?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      city?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      ward?:  {
        __typename: "ClipHgArea",
        areaId: string,
        name: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      devices?:  {
        __typename: "ModelClipHgDeviceConnection",
        items:  Array< {
          __typename: "ClipHgDevice",
          deviceId: string,
          restroomId: string,
          co2SensorUsage?: number | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null,
    toilets?:  {
      __typename: "ModelClipHgToiletConnection",
      items:  Array< {
        __typename: "ClipHgToilet",
        deviceId: string,
        name: string,
        usage: number,
        number: number,
        type: number,
        fixedFlowRate?: number | null,
        doorLockId?: string | null,
        isOccupied?: boolean | null,
        emergencyButtonId?: string | null,
        isConnected?: boolean | null,
        disconnectedTime?: string | null,
        hasDisconnectWarning?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateClipHgToiletSubscription = {
  onCreateClipHgToilet?:  {
    __typename: "ClipHgToilet",
    deviceId: string,
    name: string,
    usage: number,
    number: number,
    type: number,
    fixedFlowRate?: number | null,
    doorLockId?: string | null,
    isOccupied?: boolean | null,
    emergencyButtonId?: string | null,
    isConnected?: boolean | null,
    disconnectedTime?: string | null,
    hasDisconnectWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClipHgToiletSubscription = {
  onUpdateClipHgToilet?:  {
    __typename: "ClipHgToilet",
    deviceId: string,
    name: string,
    usage: number,
    number: number,
    type: number,
    fixedFlowRate?: number | null,
    doorLockId?: string | null,
    isOccupied?: boolean | null,
    emergencyButtonId?: string | null,
    isConnected?: boolean | null,
    disconnectedTime?: string | null,
    hasDisconnectWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClipHgToiletSubscription = {
  onDeleteClipHgToilet?:  {
    __typename: "ClipHgToilet",
    deviceId: string,
    name: string,
    usage: number,
    number: number,
    type: number,
    fixedFlowRate?: number | null,
    doorLockId?: string | null,
    isOccupied?: boolean | null,
    emergencyButtonId?: string | null,
    isConnected?: boolean | null,
    disconnectedTime?: string | null,
    hasDisconnectWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClipHgCalcDataSubscription = {
  onCreateClipHgCalcData?:  {
    __typename: "ClipHgCalcData",
    calcDataId: string,
    restroomId: string,
    deviceId: string,
    sendDateTime: string,
    carbonDioxide?: number | null,
    temperature?: number | null,
    humidity?: number | null,
    usage?: number | null,
    number?: number | null,
    type: number,
    flushCount?: number | null,
    flowTime?: number | null,
    flowRate?: number | null,
    ev?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClipHgCalcDataSubscription = {
  onUpdateClipHgCalcData?:  {
    __typename: "ClipHgCalcData",
    calcDataId: string,
    restroomId: string,
    deviceId: string,
    sendDateTime: string,
    carbonDioxide?: number | null,
    temperature?: number | null,
    humidity?: number | null,
    usage?: number | null,
    number?: number | null,
    type: number,
    flushCount?: number | null,
    flowTime?: number | null,
    flowRate?: number | null,
    ev?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClipHgCalcDataSubscription = {
  onDeleteClipHgCalcData?:  {
    __typename: "ClipHgCalcData",
    calcDataId: string,
    restroomId: string,
    deviceId: string,
    sendDateTime: string,
    carbonDioxide?: number | null,
    temperature?: number | null,
    humidity?: number | null,
    usage?: number | null,
    number?: number | null,
    type: number,
    flushCount?: number | null,
    flowTime?: number | null,
    flowRate?: number | null,
    ev?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClipHgWarningSubscription = {
  onCreateClipHgWarning?:  {
    __typename: "ClipHgWarning",
    warningId: string,
    restroomId: string,
    deviceId?: string | null,
    sendDateTime: string,
    warningNumber: number,
    warningType?: number | null,
    usage?: number | null,
    number?: number | null,
    type?: number | null,
    toiletName?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClipHgWarningSubscription = {
  onUpdateClipHgWarning?:  {
    __typename: "ClipHgWarning",
    warningId: string,
    restroomId: string,
    deviceId?: string | null,
    sendDateTime: string,
    warningNumber: number,
    warningType?: number | null,
    usage?: number | null,
    number?: number | null,
    type?: number | null,
    toiletName?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClipHgWarningSubscription = {
  onDeleteClipHgWarning?:  {
    __typename: "ClipHgWarning",
    warningId: string,
    restroomId: string,
    deviceId?: string | null,
    sendDateTime: string,
    warningNumber: number,
    warningType?: number | null,
    usage?: number | null,
    number?: number | null,
    type?: number | null,
    toiletName?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClipHgUseCountSubscription = {
  onCreateClipHgUseCount?:  {
    __typename: "ClipHgUseCount",
    useCountId: string,
    restroomId: string,
    deviceId: string,
    useDateTime: string,
    usage: number,
    type: number,
    number: number,
    washDeadline?: string | null,
    washDeviceId?: string | null,
    washNumber?: number | null,
    washTime?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClipHgUseCountSubscription = {
  onUpdateClipHgUseCount?:  {
    __typename: "ClipHgUseCount",
    useCountId: string,
    restroomId: string,
    deviceId: string,
    useDateTime: string,
    usage: number,
    type: number,
    number: number,
    washDeadline?: string | null,
    washDeviceId?: string | null,
    washNumber?: number | null,
    washTime?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClipHgUseCountSubscription = {
  onDeleteClipHgUseCount?:  {
    __typename: "ClipHgUseCount",
    useCountId: string,
    restroomId: string,
    deviceId: string,
    useDateTime: string,
    usage: number,
    type: number,
    number: number,
    washDeadline?: string | null,
    washDeviceId?: string | null,
    washNumber?: number | null,
    washTime?: string | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClipHgSubtotalSubscription = {
  onCreateClipHgSubtotal?:  {
    __typename: "ClipHgSubtotal",
    restroomId: string,
    period: string,
    maleBothUse: number,
    maleToiletUse: number,
    maleWashUse: number,
    femaleBothUse: number,
    femaleToiletUse: number,
    femaleWashUse: number,
    multiBothUse: number,
    multiToiletUse: number,
    multiWashUse: number,
    maleCarbonDioxide?: number | null,
    femaleCarbonDioxide?: number | null,
    multiCarbonDioxide?: number | null,
    latestTemperature?: number | null,
    latestHumidity?: number | null,
    maleFlowRate?: number | null,
    femaleFlowRate?: number | null,
    multiFlowRate?: number | null,
    average?: number | null,
    elapsedDays?: number | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateClipHgSubtotalSubscription = {
  onUpdateClipHgSubtotal?:  {
    __typename: "ClipHgSubtotal",
    restroomId: string,
    period: string,
    maleBothUse: number,
    maleToiletUse: number,
    maleWashUse: number,
    femaleBothUse: number,
    femaleToiletUse: number,
    femaleWashUse: number,
    multiBothUse: number,
    multiToiletUse: number,
    multiWashUse: number,
    maleCarbonDioxide?: number | null,
    femaleCarbonDioxide?: number | null,
    multiCarbonDioxide?: number | null,
    latestTemperature?: number | null,
    latestHumidity?: number | null,
    maleFlowRate?: number | null,
    femaleFlowRate?: number | null,
    multiFlowRate?: number | null,
    average?: number | null,
    elapsedDays?: number | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteClipHgSubtotalSubscription = {
  onDeleteClipHgSubtotal?:  {
    __typename: "ClipHgSubtotal",
    restroomId: string,
    period: string,
    maleBothUse: number,
    maleToiletUse: number,
    maleWashUse: number,
    femaleBothUse: number,
    femaleToiletUse: number,
    femaleWashUse: number,
    multiBothUse: number,
    multiToiletUse: number,
    multiWashUse: number,
    maleCarbonDioxide?: number | null,
    femaleCarbonDioxide?: number | null,
    multiCarbonDioxide?: number | null,
    latestTemperature?: number | null,
    latestHumidity?: number | null,
    maleFlowRate?: number | null,
    femaleFlowRate?: number | null,
    multiFlowRate?: number | null,
    average?: number | null,
    elapsedDays?: number | null,
    ttl?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateClipHgUserRestroomSubscription = {
  onCreateClipHgUserRestroom?:  {
    __typename: "ClipHgUserRestroom",
    user: string,
    restroomId: string,
    isNotify?: boolean | null,
    isHiddenSecurityWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
    restrooms?:  {
      __typename: "ModelClipHgRestroomConnection",
      items:  Array< {
        __typename: "ClipHgRestroom",
        restroomId: string,
        prefAreaId?: string | null,
        cityAreaId?: string | null,
        wardAreaId?: string | null,
        name: string,
        address: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        latestLocationTime?: string | null,
        isWinter: boolean,
        isPremium?: boolean | null,
        useMin: number,
        useMax: number,
        topPosition?: number | null,
        leftPosition?: number | null,
        isMove?: boolean | null,
        pref?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        city?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        ward?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        devices?:  {
          __typename: "ModelClipHgDeviceConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateClipHgUserRestroomSubscription = {
  onUpdateClipHgUserRestroom?:  {
    __typename: "ClipHgUserRestroom",
    user: string,
    restroomId: string,
    isNotify?: boolean | null,
    isHiddenSecurityWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
    restrooms?:  {
      __typename: "ModelClipHgRestroomConnection",
      items:  Array< {
        __typename: "ClipHgRestroom",
        restroomId: string,
        prefAreaId?: string | null,
        cityAreaId?: string | null,
        wardAreaId?: string | null,
        name: string,
        address: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        latestLocationTime?: string | null,
        isWinter: boolean,
        isPremium?: boolean | null,
        useMin: number,
        useMax: number,
        topPosition?: number | null,
        leftPosition?: number | null,
        isMove?: boolean | null,
        pref?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        city?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        ward?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        devices?:  {
          __typename: "ModelClipHgDeviceConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteClipHgUserRestroomSubscription = {
  onDeleteClipHgUserRestroom?:  {
    __typename: "ClipHgUserRestroom",
    user: string,
    restroomId: string,
    isNotify?: boolean | null,
    isHiddenSecurityWarning?: boolean | null,
    createdAt: string,
    updatedAt: string,
    restrooms?:  {
      __typename: "ModelClipHgRestroomConnection",
      items:  Array< {
        __typename: "ClipHgRestroom",
        restroomId: string,
        prefAreaId?: string | null,
        cityAreaId?: string | null,
        wardAreaId?: string | null,
        name: string,
        address: string,
        location:  {
          __typename: "Location",
          lat: number,
          lon: number,
        },
        latestLocationTime?: string | null,
        isWinter: boolean,
        isPremium?: boolean | null,
        useMin: number,
        useMax: number,
        topPosition?: number | null,
        leftPosition?: number | null,
        isMove?: boolean | null,
        pref?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        city?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        ward?:  {
          __typename: "ClipHgArea",
          areaId: string,
          name: string,
          createdAt: string,
          updatedAt: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        devices?:  {
          __typename: "ModelClipHgDeviceConnection",
          nextToken?: string | null,
        } | null,
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};
